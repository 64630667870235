import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CopyRight from "../../LoginPage/components/CopyRight";
import {observer} from "mobx-react";
import {Box, Grid} from "@material-ui/core";
import KPICard from "./components/KPICard";
import theme from "../../../themes/defaultTheme";
import {useStores} from "../../../models/hooks/useStores";
import AdminReport from "../../../models/types/AdminReport";
import {wrapWithReduxStore} from "../../../util/Utils";
import ListPanel from "./components/ListPanel";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {

        paddingTop: theme.spacing(2),
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },


}));

const adminReport = AdminReport.create({})
wrapWithReduxStore(adminReport)

export default observer(function AdminDashboardPage() {
    const classes = useStyles();
    const {MainStore} = useStores()
    const history = useHistory()


    useEffect(() => {
        adminReport.fetchAll();
    }, [])
    return (
        <div>
            <div className={classes.appBarSpacer}/>
            <main className={classes.content}>
                <Grid container justify={'space-around'}>
                    <Grid item lg={3} md={3} xs={12}><KPICard label={'New Submissions'}
                                                              color={theme.palette.common.caribbean}
                                                              textColor={'#fff'}
                                                              number={adminReport.kpis?.submissions_new_since_yesterday}
                                                              suffix={'since yesterday'}/></Grid>
                    <Grid item lg={3} md={3} xs={12}><KPICard color={theme.palette.secondary.main}
                                                              label={'Open Submissions'}
                                                              number={adminReport.kpis?.submissions_without_feedback}
                                                              suffix={'submissions'}/></Grid>
                    <Grid item lg={3} md={3} xs={12}><KPICard
                        color={(adminReport.kpis?.submissions_without_feedback_older_than_7_days ?? 0) > 0 ? theme.palette.common.berry : theme.palette.success.dark}
                        label={'Open Submissions'}
                        number={adminReport.kpis?.submissions_without_feedback_older_than_7_days}
                        suffix={'older than 7 days'}/></Grid>

                </Grid>
                <Box mt={2}>
                    <Grid container justify={'space-around'}>
                        <Grid item lg={3} md={3} xs={12}><ListPanel label={'New Submissions'}
                                                                    list={adminReport.newSubmissions}
                                                                    onItemClicked={(shortListView) => {
                                                                        history.push(`/admin/submission/${shortListView.id}`)
                                                                    }}/></Grid>
                        <Grid item lg={3} md={3} xs={12}><ListPanel list={adminReport.openSubmissions}
                                                                    onItemClicked={(shortListView) => {
                                                                        history.push(`/admin/submission/${shortListView.id}`)
                                                                    }}
                                                                    label={'Open Submissions'}/></Grid>
                        <Grid item lg={3} md={3} xs={12}><ListPanel list={adminReport.oldOpenSubmissions}
                                                                    onItemClicked={(shortListView) => {
                                                                        history.push(`/admin/submission/${shortListView.id}`)
                                                                    }}
                                                                    label={'Older Open Submissions'}/></Grid>
                    </Grid>
                </Box>
            </main>
            <div className={classes.appBarSpacer}/>
            <CopyRight/>
        </div>
    );
})
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Countdown from "react-countdown";
import Typography from "@material-ui/core/Typography";
import {useNewSubmission} from "../../hooks/SubmissionProvider";
import {NEW_SUBMISSION_STATES} from "../../../../models/types/Submission";
import theme from "../../../../themes/defaultTheme";
import {ThumbUp} from '@material-ui/icons';
import {observer} from 'mobx-react';


interface VideoOverlayProps {
}


const useStyles = makeStyles((theme) => ({
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndex.mobileStepper,
        color: theme.palette.secondary.contrastText
    },
    button: {
        position: 'absolute',
        top: theme.spacing(4),
        left: theme.spacing(4),
    },
    recordingButton: {

        backgroundColor: theme.palette.common.coral,
    }
}));

const VideoOverlay: React.FC<VideoOverlayProps> = ({}) => {
    const classes = useStyles();
    const newSubmission = useNewSubmission()

    const color = NEW_SUBMISSION_STATES.RECORDING_TIMER === newSubmission?.state ? theme.palette.secondary.main : NEW_SUBMISSION_STATES.PREP === newSubmission?.state ? theme.palette.common.salmon : NEW_SUBMISSION_STATES.AFTER_SUBMIT === newSubmission?.state ? theme.palette.common.caribbean : undefined

    return (
        <Box display="flex" alignItems="center"
             justifyContent="center" p={4} className={classes.overlay}
             style={color ? {backgroundColor: color + '66'} : undefined}>
            {newSubmission?.state === NEW_SUBMISSION_STATES.RECORDING_RECORD ?
                <div className={classes.button}>
                    <Button
                        disableElevation
                        size={'small'}
                        variant="contained"
                        color={'secondary'}
                        className={classes.recordingButton}
                        onClick={() => {
                            newSubmission?.setState(NEW_SUBMISSION_STATES.RECORDING_RECORDING_STOPPED)
                        }}
                    >
                        RECORDING
                    </Button>
                </div>
                :
                <div className={classes.button}>
                    <Button
                        disableElevation
                        size={'small'}
                        variant="contained"
                        color={'primary'}
                        onClick={() => {
                            if (newSubmission?.state === NEW_SUBMISSION_STATES.PREP) {
                                newSubmission?.setState(NEW_SUBMISSION_STATES.RECORDING_TIMER)
                            }
                        }}
                    >
                        {newSubmission?.state !== NEW_SUBMISSION_STATES.AFTER_SUBMIT ? "Not Recording" : "Submitted"}
                    </Button>
                </div>
            }
            {
                newSubmission?.state === NEW_SUBMISSION_STATES.RECORDING_TIMER &&
                <Box minWidth={'30%'}>
                    <Countdown
                        onComplete={() => newSubmission?.setState(NEW_SUBMISSION_STATES.RECORDING_RECORD)}
                        renderer={({hours, minutes, seconds, completed}) => <Typography
                            align={'center'}
                            variant={'h1'}>:{seconds || 60}</Typography>}
                        date={Date.now() + 60000}/>
                    <Button
                        disableElevation
                        size={'large'}
                        fullWidth
                        variant="outlined"
                        color={'inherit'}
                        onClick={() => {
                            newSubmission?.setState(NEW_SUBMISSION_STATES.RECORDING_RECORD)
                        }}
                    >
                        Start Now
                    </Button>
                </Box>
            }

            {
                newSubmission?.state === NEW_SUBMISSION_STATES.AFTER_SUBMIT &&
                <Box minWidth={'30%'}>
                    <Typography align={'center'} component={'span'}>
                        <Typography variant={'h2'}>
                            <ThumbUp fontSize={'inherit'}/>
                        </Typography>
                        <Typography variant={'h3'} gutterBottom>
                            Great Job!
                        </Typography>
                        <Typography variant={'h5'}>
                            Your Submission has been created
                        </Typography>
                        <Typography variant={'body2'}>
                            You Will Recieve a Notification When a Reviewer Submits Feedback
                        </Typography>
                    </Typography>
                </Box>
            }
        </Box>
    );
}

export default observer(VideoOverlay)
import {createContext, useContext} from 'react';
import {applySnapshot, types} from "mobx-state-tree";
import {Color} from "@material-ui/lab";


export const ToastType = types.model("Toast", {
    text: "",
    open: false,
    type: types.optional(types.enumeration<Color>("Color", ['success', 'info', 'warning', 'error']), 'error')
}).actions(self => ({
    showSnack(text: string, type: Color = "error") {
        applySnapshot(self, {text, type, open: true})
    },
    hideSnack() {
        applySnapshot(self, {...self, open: false})
    }
}))

export const Toast = ToastType.create();

export const ToastContext = createContext<typeof Toast>(Toast);


export const useToast = (): typeof Toast => useContext(ToastContext);

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CopyRight from "../../LoginPage/components/CopyRight";
import {observer} from "mobx-react";
import IntroductionModal from "../../../components/IntroductionModal/IntroductionModal";
import {useParams} from "react-router-dom";
import ChallengesListView from "./components/ChallengesListView";


const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        display: 'flex',
        flexGrow: 1,
        overflow: 'auto',
        flexDirection: 'column'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default observer(function StudentChallengePage() {
    const classes = useStyles();


    return (
        <div>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <ChallengesListView/>
                <div className={classes.appBarSpacer}/>
            </main>
            <CopyRight/>
            <IntroductionModal/>
        </div>
    );
})
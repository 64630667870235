import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {ArrowBack} from "@material-ui/icons";
import {useHistory, useLocation} from "react-router-dom";
import {observer} from 'mobx-react';
import {RouteState} from "../../../components/Authentication/ProtectedRoute";

interface BackToDashboardButtonProps {

}


const useStyles = makeStyles((theme) => ({
    buttonColor: {
        color: theme.palette.common.caribbean
    }
}));


const BackToDashboardButton: React.FC<BackToDashboardButtonProps> = ({}) => {
    const classes = useStyles();
    const history = useHistory()
    const location = useLocation<RouteState>();
    return (
        <Button onClick={() => {
            if (history.action !== 'POP') {
                history.goBack()
            } else if (location.state.isStudent) {
                history.push('/student/challenges')
            } else if (location.state.isAdmin) {
                history.push('/admin/submissions')
            } else {
                history.push('/postLogin')
            }
        }}
                className={classes.buttonColor} startIcon={<ArrowBack/>}>Go Back</Button>
    );
}

export default observer(BackToDashboardButton)
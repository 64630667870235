import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {Dialog, MobileStepper, useMediaQuery} from "@material-ui/core";
import theme from "../../themes/defaultTheme";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

interface IntroductionModalProps {

}


const useStyles = makeStyles((theme) => ({
    image: {
        minHeight: 300,
        maxHeight: '30%',
        width: '100%',
        position: 'relative'
    },
    stepper: {
        background: 'none',
        justifyContent: 'center',
    },
    overlay: {
        backgroundColor: theme.palette.common.caribbean + 'dd',
    },
    dot: {
        backgroundColor: '#FFFFFFAA',
        width: 15,
        height: 15,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    dotActive: {backgroundColor: '#FFFFFF'}
}));

const HEADLINES = [
    "1. Accept The Challenge",
    "2. Record & Request Feedback",
    "3. Review Feedback"
]
const CONTENT = [
    "Each Challenge has a set of fun video-based exercises students must complete.",
    "Read the question for the exercise and record your video. When you have finished, simply submit it or ask additional reviewers for feedback.",
    "Expect constructive feedback from your reviewers. Remember, it's the feedback that will make you stronger and better prepare you for the future."
]

const LOCAL_STORAGE_KEY = 'uc.intital.steps'

const IntroductionModal: React.FC<IntroductionModalProps> = ({}) => {

    const [open, setOpen] = useState<boolean>(!localStorage.getItem(LOCAL_STORAGE_KEY) ?? true);
    const [page, setPage] = useState(0);

    const classes = useStyles();

    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));


    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="initial steps modal"
            aria-describedby="intital-steps-modal"
            fullScreen={fullScreen}
            fullWidth
            maxWidth={'md'}
        >
            <CardMedia
                className={classes.image}
                image={'/assets/backgrounds/teal.png'}
            >
                <Box p={4} textAlign={'center'} className={classes.overlay}
                     color={'#fff'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={'h2'} noWrap gutterBottom>
                                Welcome to UpperCampus Prep
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box color={theme.palette.common.berry}>
                                <Typography variant={'h4'} gutterBottom>
                                    {HEADLINES[page]}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'h5'} gutterBottom>
                                {CONTENT[page]}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <MobileStepper
                                classes={{root: classes.stepper, dot: classes.dot, dotActive: classes.dotActive}}
                                variant="dots"
                                steps={3}
                                position="static"
                                activeStep={page}
                                backButton={null}
                                nextButton={null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button size="large" variant={'outlined'} color={'inherit'}
                                    endIcon={page < 2 ? <PlayArrowIcon/> : undefined}
                                    onClick={() => {
                                        if (page < 2) {
                                            setPage(page + 1)
                                        } else {
                                            localStorage.setItem(LOCAL_STORAGE_KEY, "true")
                                            setOpen(false)
                                        }
                                    }}>
                                {page < 2 ? "Next" : "Enter"}
                            </Button>
                        </Grid>
                    </Grid>


                </Box>
            </CardMedia>

        </Dialog>
    );
}

export default observer(IntroductionModal)
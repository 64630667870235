import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Typography} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import ImageSplitContainer from "../components/ImageSplitContainer";
import {useStores} from "../models/hooks/useStores";

interface MissingOrganizationPageProps {

}


const useStyles = makeStyles((theme) => ({}));

/**
 * TODO: Add better page here
 * @constructor
 */
const MissingOrganizationPage: React.FC<MissingOrganizationPageProps> = ({}) => {

    const {logout} = useAuth0();
    const {MainStore} = useStores()
    return (
        <ImageSplitContainer>
            <Box height={'inherit'} p={4}>
                <Typography variant={'h6'}>You have not been granted access by your school to use UpperCampus Prep.
                    Contact your school administrator or counselor to request access. If you still seem to have trouble,
                    have your administrator contact us at <a
                        href={"mailto:support@uppercampus.com"}>support@uppercampus.com</a>.
                    <br/><br/>
                    If you are a counselor and interested in using UpperCampus Prep, please contact us at
                    sales@uppercampus.com.</Typography>
                <Box mt={4}>
                    <Button variant={'contained'} color={'secondary'} fullWidth onClick={() => {

                        MainStore.selectOrganization(undefined)
                        logout({
                            returnTo: window.location.origin,
                        })
                    }}>Logout</Button>
                </Box>
            </Box>
        </ImageSplitContainer>
    );
}

export default observer(MissingOrganizationPage);

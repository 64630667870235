import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LoginForm from "./components/LoginForm";
import {useAuth0} from "@auth0/auth0-react";
import {useHistory} from "react-router-dom";
import ImageSplitContainer from "../../components/ImageSplitContainer";


interface LoginPageProps {

}


const useStyles = makeStyles((theme) => ({
    image: {
        //TODO add real picture here - https://trello.com/c/aXGOym8t/131-get-picture-text-for-login-page
        backgroundColor: theme.palette.primary.main,

        zIndex: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {height: '100vh'},
    grid: {height: '100%'},
    backgroundU: {
        backgroundImage: "url('/assets/backgrounds/COBALT.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 500,
        height: '50%',
        width: '50%'
    },
    loginPanel: {
        zIndex: 10
    }
}));

const LoginPage: React.FC<LoginPageProps> = ({}) => {
    const classes = useStyles();
    const {isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect} = useAuth0();
    const [authenticationLoading, setAuthenticationLoading] = useState(true);
    const history = useHistory();
    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            // loginWithRedirect();
        } else if (isAuthenticated) {
            history.push('/postLogin');
        }

    }, [isLoading, isAuthenticated]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ImageSplitContainer>
            <LoginForm/>
        </ImageSplitContainer>
    );
}
export default LoginPage
import {createContext, useContext} from 'react';
import {flow, Instance, types} from "mobx-state-tree";
import {getAxiosInstance} from "../../util/AxiosUtil";
import axios from "axios";


const UploadPart = types.model("UploadPart", {
    ETag: "",
    PartNumber: types.number,
    Size: 0,
    Completed: false
}).views(self => ({
    get awsProperties() {
        return { ETag: self.ETag, PartNumber: self.PartNumber }
    },
})).actions(self => ({
    upload: flow(function* upload(uploadReference: UploadReference, data: Blob) {
        self.Size = data.size;
        const preSignedUrl: string = (yield (yield getAxiosInstance()).post(`/multipart-uploads/pre-signed-url`,
            {uploadId: uploadReference.uploadId, bucket: uploadReference.bucket, key: uploadReference.key, partNumber: self.PartNumber})).data
        const uploadResp = (yield axios.put(
            preSignedUrl,
            data,
            // TODO make content variable
            // { headers: { 'Content-Type': "video/webm" } }
        ));
        self.ETag = uploadResp.headers.etag.substring(1, uploadResp.headers.etag.length - 1);
        self.Completed = true;
    }),
}))

export type UploadReference = {
    bucket: string,
    key: string,
    uploadId: string,
}

export enum ProgressState {
    GATHERING_DATA,
    WAITING_FOR_UPLOAD_CONFIRMATION,
    COMPLETED,
    UPLOADING
}

const FIVE_MB_IN_BYTES = 5242880;
export const FileUploadInfo = types.model("FileUploadInfo", {
        displayName: "",
        bucket: "",
        key: "",
        uploadId: types.identifier,
        parts: types.array(UploadPart),
        partCount: 0,
        completeAfterPartUploadsFinish: false,
        stillRecording: true,
        completed: false,
        discarded: false
    }
)
    .views(self => ({
    get uploadedDataSize() {
        const byteSize = self.parts.filter(p => p.Completed).reduce((sum, current) => sum + current.Size, 0);
        return byteSizeToString(byteSize);
    },
    get totalDataSize() {
        const byteSize = self.parts.reduce((sum, current) => sum + current.Size, 0);
        return byteSizeToString(byteSize);
    },
    get completedPartsCount() {
        return self.parts.filter(p => p.Completed).length;
    },
    get isPending() {
        return !(self.discarded || self.completed);
    },
})).views(self => ({
    get progressState() {
        if (self.stillRecording) return ProgressState.GATHERING_DATA;
        if (!self.completeAfterPartUploadsFinish) return ProgressState.WAITING_FOR_UPLOAD_CONFIRMATION;
        if (self.completed) return ProgressState.COMPLETED;
        return ProgressState.UPLOADING;
    },
    get awsPartsProperties() {
        return self.parts.map(x => x.awsProperties);
    },
    get uploadReference() {
        return {uploadId: self.uploadId, bucket: self.bucket, key: self.key};
    },
})).actions(self => ({
    finishRecording() {
        self.stillRecording = false;
    },
    discard() {
        self.discarded = true;
    },
    completeUploadIfReady: flow(function* completeUploadIfReady() {
        if (self.partCount == self.completedPartsCount && self.completeAfterPartUploadsFinish && !self.completed) {
            let completionRequest = {uploadId: self.uploadId, bucket: self.bucket, key: self.key, parts: self.awsPartsProperties};
            const completionResponse = (yield (yield getAxiosInstance()).post(`/multipart-uploads/complete`, completionRequest)).data
            console.log(completionResponse);
            self.completed = true;
        }
    }),
})).actions(self => ({
    addPartAndUpload: flow(function* addPart(data: Blob) {
        // upload data and add to parts
        const partNumber = ++self.partCount;
        let uploadPart = UploadPart.create({ PartNumber: partNumber });
        self.parts.push(uploadPart);
        yield uploadPart.upload(self.uploadReference, data)
        self.completeUploadIfReady();
    }),
    complete() {
        self.completeAfterPartUploadsFinish = true;
        self.completeUploadIfReady();
        console.log(`completing ${self.key}`);
        return self.key;
    },
}))
    .volatile(self => ({
        pendingBytes: [] as Blob[]
    }))
    .actions(self => ({
        addPendingBytes(value: Blob) {
            self.pendingBytes.push(value);
            const totalSize = self.pendingBytes.reduce((sum, current) => sum + current.size, 0);
            if (totalSize > FIVE_MB_IN_BYTES) {
                self.addPartAndUpload(new Blob(self.pendingBytes));
                self.pendingBytes = [];
            }
        },
        forcePendingBytePartUpload() {
            self.addPartAndUpload(new Blob(self.pendingBytes));
            self.pendingBytes = [];
        }
    }))

export const FileUploaderType = types.model("FileUploaderType", {
    files: types.map(FileUploadInfo),
}).views(self => ({
    get filesArray() {
        return [...self.files.values()];
    },
    get hasUploads() {
        return [...self.files.values()].filter(f => !f.discarded).length > 0;
    },
    get hasPendingUploads() {
        return [...self.files.values()].filter(f => f.isPending).length > 0;
    },
    get pendingUploadsCount() {
        return [...self.files.values()].filter(f => f.isPending).length;
    },
})).actions(self => ({
    addFileUpload(displayName: string, uploadRef: UploadReference): Instance<typeof FileUploadInfo> | undefined {
        // start multipart upload and add to files with uploadId
        let uploadInfo = { displayName, ...uploadRef, parts: [] };
        self.files.set(uploadRef.uploadId, uploadInfo)
        return self.files.get(uploadRef.uploadId);
    }
}))


const units = ['bytes', 'KB', 'MB', 'GB'];
// byteSizeToString(6544528) ->> 6.2 MB
// byteSizeToString(23483023) ->> 22 MB
function byteSizeToString(byteSize: number){
    let l = 0, n = byteSize || 0;
    while(n >= 1024 && ++l){
        n = n/1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

//TODO add custom popup
window.onbeforeunload = function(event: BeforeUnloadEvent) {
    if(!FileUploader.hasPendingUploads) {
        return;
    }
    // the text is not really taken into account
    let dialogText = 'You have pending uploads. Are you sure?';
    event.returnValue = dialogText;
    return dialogText;
};

export const FileUploader = FileUploaderType.create();

export const FileUploadContext = createContext<typeof FileUploader>(FileUploader);

export const useFileUploader = (): typeof FileUploader => useContext(FileUploadContext);

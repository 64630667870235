import React from 'react';
import {observer} from 'mobx-react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {IAnyType, Instance} from 'mobx-state-tree';
import {ListType} from '../../models/types/listTypes/ListType'
import {DataGrid, DataGridProps} from "@material-ui/data-grid";
import defaultTheme from "../../themes/defaultTheme";
import clsx from "clsx";

interface GridWithListTypeProps<T extends IAnyType> extends Partial<DataGridProps> {
    listType: Instance<ListType<T>>,
    table?: boolean
}


const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                border: 0,
                '& .MuiDataGrid-columnsContainer': {
                    border: 0
                },
                '& .MuiDataGrid-row': {
                    // marginBottom: theme.spacing(2),
                    borderRadius: 4
                },
                '& .MuiDataGrid-cell': {
                    border: 0
                },
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 0,
                    outlineWidth: 0
                },
            },
        }),
    {defaultTheme},
);

/**
 * TODO: Add backend sorting/filtering
 * @param listType
 * @param table if you want it to look like a table
 * @param dataGridProps
 * @constructor
 */
function GridWithListType<T extends IAnyType>({listType, table = false, ...dataGridProps}: GridWithListTypeProps<T>) {
    const classes = useStyles();

    return (
        <DataGrid
            rowHeight={!table ? 75 : undefined}
            className={!table ? clsx(classes.root) : undefined}
            autoHeight

            disableSelectionOnClick

            {...dataGridProps}

            rows={listType.rows}
            columns={listType.columns}
            pagination
            rowsPerPageOptions={[10]}
            pageSize={listType.pageSize}
            rowCount={listType.count}
            paginationMode="server"
            onPageChange={(page) => listType.fetchPage(page + 1)}
            loading={listType.loading}
        />
    );
}

export default observer(GridWithListType)
import React, {useCallback} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useSubmission} from "../../SubmissionPage/hooks/SubmissionProvider";
import {Typography} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {Feedback} from "../../../models/types/Submission";
import {Instance} from "mobx-state-tree";
import {Skeleton} from "@material-ui/lab";
import ExpandableText from "../../../components/ExpandableText";
import {observer} from "mobx-react";
import useQuery from '../../../models/hooks/useQuery';
import ReviewSubmissionSection from "../../SubmissionPage/ReviewSubmissionPage/components/ReviewSubmissionSection";

interface FeedbackComponentViewProps {
    allowNewFeedback?: boolean
}


const useStyles = makeStyles((theme) => ({
    timeStamp: {
        textTransform: 'none',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: 'inherit'
    },
    feedbackBox: {
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.primary.main
    },
    ribbon: {
        backgroundColor: theme.palette.common.caribbean,
        position: "absolute",
        color: 'white',
        width: 120,
        zIndex: 3,
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: 5,
        '&::before': {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: 'block',
            border: '5px solid #2980b9',
        },
        '&::after': {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: 'block',
            border: '5px solid #2980b9',
        },
        transform: 'rotate(45deg)',
        top: 10,
        right: -42,
    },
    span: {}
}));

const FeedbackComponentView: React.FC<FeedbackComponentViewProps> = ({allowNewFeedback = false}) => {

    const submission = useSubmission()

    const classes = useStyles();
    const onRefChange = useCallback(node => {
        setTimeout(() => {
            try {
                if (node) {
                    const headerOffset = 100;
                    const elementPosition = node.getBoundingClientRect().top;
                    const offsetPosition = elementPosition - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });
                }
            } catch (e) {
                console.error(e)
            }
        }, 1000)

    }, []);

    let query = useQuery();
    const queryParamFeedbackId = query.get('feedback')

    const feedbacks: Instance<typeof Feedback>[] | undefined[] = submission?.feedbackSortByDate ?? new Array(3).fill(undefined)


    return (
        <div>
            {allowNewFeedback && <ReviewSubmissionSection isAdmin clearInput/>}
            {!(submission?.hasFeedback ?? true) && <Typography>No Feedback yet!</Typography>}
            <Grid container spacing={4}>
                {feedbacks.map((feedback: Instance<typeof Feedback> | undefined, index: number) => (
                    <Grid item xs={12}
                          key={feedback?.id ?? index}>
                        <Box p={4} color={'#fff'} className={classes.feedbackBox}>
                            {queryParamFeedbackId === feedback?.id && <div ref={onRefChange}/>}
                            {feedback?.isAutomated &&
                            <div className={classes.ribbon}><Typography variant={"body2"}>BETA</Typography>
                            </div>}
                            <ExpandableText text={feedback?.summary}
                                            initialExpansionstate={queryParamFeedbackId ? queryParamFeedbackId === feedback?.id : false}/>
                        </Box>
                        <Box display={'flex'} justifyItems={'center'} justifyContent={'space-between'}
                             width={'100%'}>
                            <Typography>
                                {feedback?.creator?.displayName ?? <Skeleton width={70}/>}
                            </Typography>
                            <Typography className={classes.timeStamp}>
                                {feedback?.postedRelativlyToNow ?? <Skeleton width={20}/>}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default observer(FeedbackComponentView);
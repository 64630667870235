import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CopyRight from "../../LoginPage/components/CopyRight";
import {observer} from "mobx-react";
import SubmissionsListView from "./components/SubmissionsListView";


const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        display: 'flex',
        flexGrow: 1,
        overflow: 'auto',
        flexDirection: 'column'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },


}));

export default observer(function SubmissionsPage() {
    const classes = useStyles();
    return (
        <div>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <SubmissionsListView/>
            </main>
            <CopyRight/>
        </div>
    );
})
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {observer} from "mobx-react";
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import NewSubmissionPage from "./NewSubmissionPage/NewSubmissionPage";
import ViewSubmissionPage from "../ViewSubmissionPage/ViewSubmissionPage";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {},
    paper: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        flexDirection: 'column'
    },
    fixedHeight: {
        height: 240,
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    buttonColor: {
        color: theme.palette.common.caribbean
    },
    breadCrumbs: {
        color: theme.palette.common.coral
    },
    flex: {
        display: 'flex'
    },
    upperContainer: {
        height: '60%'
    },
    lowerContainer: {
        height: '40%'
    },
    goButton: {
        backgroundColor: theme.palette.common.caribbean,
        padding: theme.spacing(2)
    },
    blueBG: {
        backgroundColor: theme.palette.primary.main,
        minHeight: '100vh'
    },
    box: {
        display: 'inline-block',
        width: 20,
        height: 20,
        marginRight: theme.spacing(1),
        alignSelf: 'center'
    },
}));

export default observer(function SubmissionPage() {

    let match = useRouteMatch();
    return (
        <Switch>
            <Route path={`${match.path}/new/:challengeId`} component={NewSubmissionPage}/>
            <Route path={`${match.path}/:submissionId`} component={ViewSubmissionPage}/>
        </Switch>
    );
})
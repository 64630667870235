import React, {useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';


import {Route, useHistory, useLocation} from "react-router-dom";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {RouteProps} from "react-router";
import {accessTokenUtil} from "../../util/AxiosUtil";
import {ProtectedRouteProvider} from "../../models/hooks/useProtectedRoute";
import {useStores} from "../../models/hooks/useStores";

export type RouteState = {
    isAdmin?: boolean,
    isStudent?: boolean,
}

interface ProtectedRouteProps extends RouteProps {
    component: React.ComponentType
}

const ProtectedRoute: React.FC<ProtectedRouteProps & RouteState> = ({component, isAdmin, isStudent, ...args}) => {

    const {getAccessTokenSilently} = useAuth0();
    const location = useLocation<any>();
    const history = useHistory();
    const {MainStore} = useStores()
    location.state = {...location.state, isAdmin, isStudent}
    useEffect(() => {
        if (isAdmin && MainStore.currentUser && !MainStore.isAdmin) {
            history.push('/postLogin')
        }
    }, [isAdmin, MainStore.isAdmin]);


    accessTokenUtil.get = getAccessTokenSilently;
    const Component = useMemo(() => withAuthenticationRequired(component, {
        returnTo: location.pathname + location.search
        // onRedirecting: () => <div>Loading</div>,
    }), [component])
    return (<ProtectedRouteProvider><Route
        component={Component}
        {...args}
    /></ProtectedRouteProvider>)
}

export default React.memo(observer(ProtectedRoute), (prevProps, nextProps) => {
    return prevProps.location?.pathname === nextProps.location?.pathname
})
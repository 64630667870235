import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Skeleton} from "@material-ui/lab";
import Card from "@material-ui/core/Card";
import {Divider} from "@material-ui/core";
import theme from "../../../../themes/defaultTheme";

interface KPICardProps {
    label?: string,
    number?: number
    suffix?: string
    color?: string
    textColor?: string
}


const useStyles = makeStyles((theme) => ({
    background: {
        height: '100%',
    },
    box: {
        minHeight: 300,
    }
}));

const KPICard: React.FC<KPICardProps> = ({label, number, suffix, color = theme.palette.primary.main, textColor}) => {
    const classes = useStyles();
    if (!textColor) {
        textColor = theme.palette.getContrastText(color);
    }
    return (
        <Card elevation={0} className={classes.box}>
            <Box className={classes.background} style={{backgroundColor: color}}
                 color={textColor} p={4}>
                <Grid container spacing={6}>
                    <Grid xs={12} item>
                        <Typography variant="button">
                            {label}
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={6} container item justify={'flex-end'}>
                        <Typography variant="h1" align={'right'}>
                            {number ?? <Skeleton/>}
                        </Typography>
                    </Grid>
                    <Grid xs={6} container item alignContent={'flex-end'}>
                        <Typography variant="h6" align={'right'}>
                            {suffix ?? <Skeleton width={'50%'}/>}
                        </Typography>
                    </Grid>
                    <Grid xs={12} item><Divider style={{backgroundColor: textColor}}/></Grid>
                </Grid>
            </Box>
        </Card>
    );
}

export default observer(KPICard)
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import theme from "../themes/defaultTheme";
import {Variant} from "@material-ui/core/styles/createTypography";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import {ListType} from "../models/types/listTypes/ListType";
import {Instance} from "mobx-state-tree";
import {useHistory, useRouteMatch} from "react-router-dom";

export interface TableHeaderWithFilterProps {
    text?: string
    boxColor?: string,
    variant?: Variant | 'inherit';
    list: Instance<ListType<any>>
}


const useStyles = makeStyles((theme) => ({
    box: {
        display: 'inline-block',
        width: theme.typography.h5.fontSize,
        height: theme.typography.h5.fontSize,
        marginRight: theme.spacing(1),
        alignSelf: 'center'
    },
    title: {
        display: 'inline-block',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
}));

const TableHeaderWithFilter: React.FC<TableHeaderWithFilterProps> = ({
                                                                         text,
                                                                         boxColor = theme.palette.common.salmon,
                                                                         variant = 'h5',
                                                                         list
                                                                     }) => {

    const classes = useStyles();
    const history = useHistory()
    let match = useRouteMatch();

    const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
        const filter = event.target.value as string | undefined;
        if (filter) {
            list.setFilterByKey(filter)
            list.fetch()

            history.push(`${match.path}${list.filtersAsURLParamString}`)
        }
    };

    return (
        <div className={classes.titleContainer}>
            <div className={classes.box} style={{backgroundColor: boxColor}}/>
            <FormControl>
                <Select
                    labelId="table filter"
                    id="table filter"
                    value={list.activeFirstFilter?.key ?? ''}
                    onChange={handleChange}
                >
                    {Array.from(list.availableFilters?.values() ?? []).map(filter => <MenuItem
                        key={filter.key}
                        value={filter.key}>{filter.label}</MenuItem>)}
                </Select>
            </FormControl>
        </div>
    );
}

export default observer(TableHeaderWithFilter)
import React from 'react';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {useStores} from "../../../models/hooks/useStores";
import {observer} from "mobx-react";

interface CopyRightProps {
    name?: string
}

const useStyles = makeStyles((theme) => ({
    footer: {
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#fff'
    }
}))

const CopyRight: React.FC<CopyRightProps> = observer(({name = 'UpperCampus'}) => {

    const classes = useStyles();
    const {MainStore} = useStores()

    return (
        <Box display={'flex'} className={classes.footer} p={2} boxShadow={3} flexDirection={'row'}
             justifyContent={'space-between'}>
            <Box display={'flex'}>
                <Typography variant={"body2"} color="textSecondary">
                    {'Copyright © '}
                    <Link color="inherit" href="https://uppercampus.com">
                        {name}
                    </Link>{' '}
                    {new Date().getFullYear()}
                </Typography>
            </Box>
            <Box display={'flex'}>
                <Typography variant={"body2"} color="textSecondary">
                    {MainStore?.currentUser?.requestedOrganizationContext?.name}
                </Typography>
            </Box>
        </Box>
    );
})

export default CopyRight
import Submission, {Creator} from "../Submission";
import {getAxiosInstance} from "../../../util/AxiosUtil";
import MainStore from "../../stores/MainStore";
import {ChallengeTypeWithoutProcessors} from "../Challenge";
import React from "react";
import SimpleTwoLineCell from "../../../components/Tables/cells/SimpleTwoLineCell";
import {Instance} from "mobx-state-tree";
import ActionButtonWithHistory from "../../../components/Tables/cells/ActionButtonWithHistory";
import createListType, {ShortListViewType} from "./ListType";

const renderShortView = (submission: Instance<typeof Submission>): ShortListViewType => ({
    title: submission.creatorName,
    id: submission.id,
    picture: submission.challenge?.picture,
    subtitle: submission.postedRelativlyToNow
})


export const AdminSubmissionListType = createListType(Submission, {
    seeAllClickURL: '/admin/submissions',
    fetchResults: async function (page, pageSize, filter) {
        return (await (await getAxiosInstance()).get<any>(`/organizations/${MainStore.organizationId}/submissions`, {
            params: {
                page: page,
                limit: pageSize,
                ...filter
            }
        })).data
    },
    availableFilters: [
        {
        key: 'submitted_in_last_24_hours', value: true, label: 'Submitted in last 24h'
    }, {
        key: 'completed', value: true, label: 'Completed'
    }, {
        key: 'not_completed', url_param: 'completed', value: false, label: 'Not Completed'
    }, {
        key: 'has_no_feedback_more_than_a_week', value: true, label: 'Old Submissions Without Feedback'
    },
    ],
    renderShortView,
    tableColumns: [
        {field: 'id', headerName: 'ID', hide: true},
        {
            field: 'picture',
            headerName: 'Picture',
            width: 90,
            sortable: false,
            disableColumnMenu: true,
            filterable: false,
            editable: false,
            renderCell: ((params) => {
                const challenge = params.row.main.challenge as typeof ChallengeTypeWithoutProcessors['CreationType']
                return <img alt={challenge.title} src={challenge?.picture} width={'100%'} height={'100%'}
                            style={{objectFit: 'cover'}}/>
            }),
            renderHeader: (params) => <React.Fragment/>,
        },
        {
            field: 'challenge.category', headerName: 'Challenge', width:110,
        },
        {
            field: 'challenge', headerName: 'Exercise', flex: 5,
            renderCell: ((params) => {
                const challenge = params.value as any as typeof ChallengeTypeWithoutProcessors['CreationType']
                return <SimpleTwoLineCell firstLine={challenge?.title}
                                          secondLine={''}/>
            })
        },
        {
            field: 'completion', headerName: 'Submission Date', flex: 4,
            renderCell: ((params) => {
                const submission = params.row.main as any as Instance<typeof Submission>
                return <SimpleTwoLineCell firstLine={"Exercise submitted on"}
                                          secondLine={submission?.createdat?.format('L') + ""}/>
            })
        },

        {
            field: 'creator', headerName: 'Submitter', flex: 5,
            renderCell: ((params) => {
                const student = params.value as any as typeof Creator['CreationType']
                return <SimpleTwoLineCell firstLine={student.name as string}
                                          secondLine={student.email as string}/>
            })
        },
        {field: 'completedYN', headerName: 'Completed',  width:110},
        {field: 'feedback_count', headerName: 'Reviews', align: 'center', width:90},
        {
            field: 'open', headerName: '', width:100,
            renderHeader: (params) => <React.Fragment/>,
            renderCell: ((params) => {
                const submission = params.row.main as any as Instance<typeof Submission>
                return <ActionButtonWithHistory
                    onClick={(history => history.push(`/admin/submission/${submission.id}`))}>
                    View
                </ActionButtonWithHistory>
            })
        },
    ]
})

export const StudentSubmissionListType = createListType(Submission, {
    fetchResults: async function (page, pageSize, filter) {
        return (await (await getAxiosInstance()).get<any>(`/organizations/${MainStore.organizationId}/users/${MainStore.userId}/submissions`,
            {
                params: {
                    page: page,
                    limit: pageSize,
                    ...filter
                }
            })).data
    },
    renderShortView,
    tableColumns: [
        {field: 'id', headerName: 'ID', hide: true},
        {
            field: 'picture', headerName: 'Picture', width: 90,
            sortable: false,
            disableColumnMenu: true,
            filterable: false,
            editable: false,
            valueGetter: (params) => {
                return params.row.main?.challenge
            },
            renderCell: ((params) => {
                const value = params.value as any as typeof ChallengeTypeWithoutProcessors['CreationType']
                return <img alt={value.title} src={value?.picture} width={'100%'} height={'100%'}
                            style={{objectFit: 'cover'}}/>
            }),
            renderHeader: (params) => <React.Fragment/>,
        },
        {
            field: 'challenge.category', headerName: 'Challenge', flex: 1,

        },
        {
            field: 'challenge', headerName: 'Exercise', flex: 3,
            renderCell: ((params) => {
                const challenge = params.value as any as typeof ChallengeTypeWithoutProcessors['CreationType']
                return <SimpleTwoLineCell firstLine={challenge?.title}
                                          secondLine={''}/>
            })
        },
        {
            field: 'completion', headerName: 'Submission Date', flex: 3,
            renderCell: ((params) => {

                const submission = params.row.main as any as Instance<typeof Submission>
                return <SimpleTwoLineCell firstLine={"Exercise submitted on"}
                                          secondLine={submission?.createdat?.format('L') + ""}/>
            })
        },

        {field: 'completedYN', headerName: 'Completed', flex: 1},
        {field: 'feedback_count', headerName: 'Reviews', align: 'center', flex: 1},
        {
            field: 'open', headerName: '', flex: 1,
            renderHeader: (params) => <React.Fragment/>,
            renderCell: ((params) => {
                const submission = params.row.main as any as Instance<typeof Submission>
                return <ActionButtonWithHistory
                    onClick={(history => history.push(`/student/submission/${submission.id}`))}>
                    View
                </ActionButtonWithHistory>
            })
        },
    ]
})
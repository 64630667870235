import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {useNewSubmission} from "../../../../hooks/SubmissionProvider";
import {NEW_SUBMISSION_STATES} from "../../../../../../models/types/Submission";

interface SubmissionStepperProps {

}


const useStyles = makeStyles((theme) => ({
    breadCrumbs: {
        color: theme.palette.common.coral
    },
    bold: {
        fontWeight: 'bold'
    }
}));

const StepperItem = ({children, bold = false}: { children: string, bold?: boolean }) => {
    const classes = useStyles();
    return <Typography variant={'button'} className={clsx(classes.breadCrumbs, {
        [classes.bold]: bold,
    })}>{children}</Typography>
}

const SubmissionStepper: React.FC<SubmissionStepperProps> = ({}) => {
    const classes = useStyles();
    const newSubmission = useNewSubmission();
    return (
        <Box flexGrow={1} alignItems={'center'}>
            <Typography variant={'button'} className={classes.breadCrumbs}>
                <StepperItem bold={newSubmission?.state === NEW_SUBMISSION_STATES.PREP}>Challenge
                    Prep</StepperItem> - <StepperItem
                bold={newSubmission?.state === NEW_SUBMISSION_STATES.RECORDING_TIMER || newSubmission?.state === NEW_SUBMISSION_STATES.RECORDING_RECORD || newSubmission?.state === NEW_SUBMISSION_STATES.RECORDING_RECORDING_STOPPED}>Recording</StepperItem> -
                <StepperItem
                    bold={newSubmission?.state === NEW_SUBMISSION_STATES.AFTER_SUBMIT}> Submission</StepperItem></Typography>
        </Box>
    );
}

export default observer(SubmissionStepper)
import {onSnapshot, SnapshotIn, types} from "mobx-state-tree"
import {NullFilterProcessor} from "./Utils/ModelUtil";
import {flow} from "mobx";
import {getAxiosInstance} from "../../util/AxiosUtil";

export const Feature = types.model('Feature',
    {
        "feature": types.maybe(types.string),
        "active": false
    }
)

export const OrganizationContext = types.model("OrganizationContext", {
    id: types.identifier,
    entity_type: "",
    name: "Unknown Organization",
    state: "Unknown_State",
    roles: types.array(types.string),
    features: types.array(Feature)
})


const OrganisationContextTransformer = types.snapshotProcessor(OrganizationContext,
    {
        //Here we transform the challenge from the backend to the model in the way we need it
        preProcessor(snapshot: SnapshotIn<any>): typeof OrganizationContext["CreationType"] {
            if (snapshot) {
                try {
                    return {...snapshot.organization, roles: snapshot.roles, state: snapshot.state};
                } catch (e) {
                    console.error('error while transforming OrganisationContextTransformer', e);
                    return snapshot;
                }
            }
            return snapshot
        }
    }
)

export const User = NullFilterProcessor(types.model("User", {
    id: types.identifier,
    email: types.optional(types.string, ''),
    firstname: 'Unknown User',
    lastname: types.optional(types.string, ''),
    picture: types.optional(types.string, ''),
    requestedOrganizationContext: types.maybe(OrganisationContextTransformer),
    organizations: types.array(OrganisationContextTransformer),
    preregistrations: types.array(OrganizationContext),
    _roles: types.array(types.string)
})
    .actions((self) => ({
        //TODO: Add a user profile that can be changed - https://trello.com/c/5QFGa9Eb/130-user-profile-page-let-the-user-change-their-name
        save: () => {
            // console.log('saved user ', self.id)
        }
    })).actions(self => ({
        afterCreate() {
            onSnapshot(self, self.save)
        }
    }))
    .views(self => ({
        get openInvites() {
            return self.organizations.filter(org => org.state === 'PENDING')
        },
    }))
    .views(self => ({
        get name() {
            return [self.firstname, self.lastname].join(' ')
        },
        get isUCAdmin() {
            return self._roles.includes('UC-Admin')
        },
        get hasMultipleOrganizations() {
            return self.organizations.length > 1
        },
        get hasOpenInvite() {
            return self.openInvites.length > 0
        },
        get firstInvite() {
            return self.openInvites[0]
        }
    })).actions(self => ({
        acceptFirstPreregistration: flow(function* acceptFirstPreregistration() {
            const response = (yield (yield getAxiosInstance()).post(`/users/currentuser/preregister/accept`, {
                "organizationId": self.firstInvite.id,
                "role": "Admin"
            })).data
        }),
        declineFirstPreregistration: flow(function* declineFirstPreregistration() {
            const response = (yield (yield getAxiosInstance()).post('/users/currentuser/preregister/decline', {
                organizationId: self.firstInvite.id,
            })).data;
        })
    })));


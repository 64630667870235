import {flow, types} from "mobx-state-tree";
import {getAxiosInstance} from "../../util/AxiosUtil";
import MainStore from "../stores/MainStore";
import {AdminSubmissionListType} from "./listTypes/SubmissionListType";

const AdminKPI = types.model("AdminKPIs", {
    submissions_new_since_yesterday: 0,
    feedbacks_new_since_yesterday: 0,
    submissions_without_feedback: 0,
    submissions_without_feedback_older_than_7_days: 0
})


export const AdminReport = types.model("AdminReport", {
    kpis: types.maybe(AdminKPI),
    newSubmissions: types.optional(AdminSubmissionListType, {

        filter: ['submitted_in_last_24_hours'],
        loading: true,
        pageSize: 5
    }),
    openSubmissions: types.optional(AdminSubmissionListType, {
        filter: ['not_completed'],
        loading: true,
        pageSize: 5
    }),
    oldOpenSubmissions: types.optional(AdminSubmissionListType, {
        filter: ['has_no_feedback_more_than_a_week'],
        loading: true,
        pageSize: 5
    })
}).actions(self => ({
    fetchKPIs: flow(function* fetchKPIs() {
        const response = (yield (yield getAxiosInstance()).get(`organizations/${MainStore.organizationId}/reporting`))
        self.kpis = response.data
    }),
})).actions(self => ({
    fetchAll: flow(function* fetchAll() {
        const fetchPromises = [
            self.fetchKPIs(),
            self.newSubmissions.fetch(),
            self.openSubmissions.fetch(),
            self.oldOpenSubmissions.fetch()]
        return Promise.all(fetchPromises)
    })
}))

export default AdminReport
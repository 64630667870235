import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import NavBar from "../../../../components/NavBar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import SubmissionContentPaper from "../../components/SubmissionContentPaper";
import {useFeedback, useSubmission} from "../../hooks/SubmissionProvider";
import ReactPlayer from "react-player";
import Grid from "@material-ui/core/Grid";
import ReviewSubmissionSidepanel from "./ReviewSubmissionSidepanel";
import ChallengeDetailSection from "../../components/ChallengeDetailSection";
import {observer} from "mobx-react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {Skeleton} from "@material-ui/lab";
import ReviewSubmissionSection from "./ReviewSubmissionSection";
import {Button} from "@material-ui/core";

interface ReviewSubmissionProps {

}

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    blueBG: {
        backgroundColor: theme.palette.primary.main,
        minHeight: '100vh'
    },
    reviewAreaContainer: {
        backgroundColor: theme.palette.secondary.main
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        alignSelf: 'center'
    },
    link: {
        color: 'none',
        textDecoration: 'none'
    }
}));

const ReviewSubmission: React.FC<ReviewSubmissionProps> = ({}) => {
    const classes = useStyles();


    const submission = useSubmission();

    let sidePanel = <ReviewSubmissionSidepanel/>

    const submittedFeedback = useFeedback()

    return (
        <Box className={classes.blueBG}>
            <NavBar elevation={0}/>
            <div className={classes.appBarSpacer}/>
            <Container maxWidth='lg'>
                <SubmissionContentPaper
                    VideoComponent={
                        <Box
                            display={'flex'}
                            overflow={'hidden'}
                            justifyContent={'center'}
                        >
                            <ReactPlayer controls url={submission?.mp4VideoLink}/>
                        </Box>
                    }
                    SidePanelComponent={sidePanel}
                    bottomPanelPadding={0}
                    BottomPanelComponent={
                        <Grid container>
                            <Grid item xs={12}>
                                <Box p={5} className={classes.reviewAreaContainer} color={'white'}>
                                    <Grid item xs={12} container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box display={'flex'} flexDirection={'row'}>
                                                <Avatar alt="Submitter Name"
                                                        className={classes.large}/>
                                                <Box ml={2} fontSize={'0.5em'}>
                                                    <Typography>
                                                        {submission?.creator?.displayName ?? <Skeleton width={150}/>}
                                                    </Typography>
                                                    <Typography variant={'body2'}>
                                                        {submission?.creator?.organizationName ??
                                                        <Skeleton width={100}/>}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ReviewSubmissionSection
                                                placeholder={'Read the exercise description and watch the student submitted video.  Please provide your honest feedback and professional suggestions for this student.'}/>
                                        </Grid>
                                        {
                                            !!submittedFeedback && <Grid item xs={12}>
                                                <a className={classes.link} target="_blank"
                                                   href='https://uppercampus.com'>
                                                    <Button variant={'contained'} color={'primary'} size={'large'}
                                                            fullWidth>THANK
                                                        YOU FOR YOUR
                                                        REVIEW! - FIND OUT MORE ABOUT UPPERCAMPUS!</Button>
                                                </a>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={5}>
                                    <ChallengeDetailSection full/>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                />
            </Container>
        </Box>
    );
}

export default observer(ReviewSubmission);
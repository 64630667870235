import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, MenuItem, TableCell, TextField} from "@material-ui/core";
import {headerType} from "./CsvUploadModal";

interface HeaderSelectionProps {
    onChange?: (header: headerType) => void,
    headers: headerType[]
    selectedHeader: headerType,
    error: boolean
}


const useStyles = makeStyles((theme) => ({}));

const HeaderSelection: React.FC<HeaderSelectionProps> = ({onChange, error, headers, selectedHeader}) => {

    return (
        <TableCell>
            <FormControl>
                <TextField
                    error={error}
                    value={selectedHeader.key}
                    select
                    helperText={error ? 'duplicated column' : undefined}
                    onChange={(selection) => {
                        const foundHeader = headers.find(header => selection.target.value === header.key);
                        if (foundHeader && onChange) {
                            onChange(foundHeader)
                        }
                    }}
                >
                    {headers.map(header => <MenuItem key={header.key} value={header.key}>{header.display}</MenuItem>)}
                </TextField>
            </FormControl>
        </TableCell>
    );
}

export default observer(HeaderSelection)
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import theme from "../../../themes/defaultTheme";
import {Box} from "@material-ui/core";

interface SimpleTwoLineCellProps {
    firstLine: string
    secondLine: string
}


const useStyles = makeStyles((theme) => ({}));

const SimpleTwoLineCell: React.FC<SimpleTwoLineCellProps> = ({firstLine, secondLine}) => {

    return (
        <Box display='flex' flexDirection={'column'}>
            <Typography variant="overline">
                {firstLine}
            </Typography>
            <Typography variant="caption" style={{color: theme.palette.common.salmon}}>
                {secondLine}
            </Typography>
        </Box>
    );
}

export default observer(SimpleTwoLineCell)
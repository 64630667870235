import React from 'react';
import {observer} from 'mobx-react';
import {List, ListItem, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useNotifications} from "../../../models/hooks/useNotifications";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    list: {
        width: 350,
        maxWidth: '100%'
    }
}));

const NotificationList: React.FC = ({}) => {


    const classes = useStyles();
    const NotificationList = useNotifications()
    const history = useHistory()
    return (
        <List className={classes.list} dense={true}>
            {NotificationList.newestUnreadNotifications.map((notification, index) =>
                <ListItem button onClick={() => {
                    NotificationList.markNotificationAsRead(notification)
                    if (notification.forwardUrl) {
                        history.push(notification.forwardUrl)
                    }
                }} key={notification.id} selected={notification.seen}
                          divider={NotificationList.hasMoreNotifications ? true : index !== NotificationList.newestUnreadNotifications.length - 1}>
                    <ListItemText
                        primary={notification.title}
                        secondary={notification.message ? notification.message : null}
                    />
                </ListItem>
            )}
            {!NotificationList.hasUnreadNotifications &&
            <ListItem
                divider={NotificationList.hasMoreNotifications}>
                <ListItemText
                    primary={'No new notifications'}

                />
            </ListItem>
            }
            {NotificationList.hasMoreNotifications &&
            <ListItem button onClick={() => console.log('All notification')}>
                <ListItemText
                    primary={'See all notifications'}

                />
            </ListItem>
            }
        </List>
    );
}

export default observer(NotificationList)
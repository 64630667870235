import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import Box from "@material-ui/core/Box";
import Webcam from "react-webcam";
import ReactPlayer from "react-player";
import {useNewSubmission} from "../../hooks/SubmissionProvider";
import {NEW_SUBMISSION_STATES} from "../../../../models/types/Submission";
import {useMediaSettings} from "../../../../models/hooks/useMediaSettings";

interface SubmissionRecordingComponentProps {
    overlay?: React.ReactNode,
}

const SubmissionRecordingComponent: React.FC<SubmissionRecordingComponentProps> = ({overlay = null}) => {

    const webcamRef = React.useRef<Webcam>(null);
    const mediaRecorderRef = React.useRef<MediaRecorder>();
    const [url, setUrl] = React.useState("");
    const recordedChunks = React.useRef<Blob[]>([]);

    const MediaSettings = useMediaSettings()
    const newSubmission = useNewSubmission()

    useEffect(() => {
        if (newSubmission?.state === NEW_SUBMISSION_STATES.RECORDING_RECORD) {
            handleStartCaptureClick()
        } else if (newSubmission?.state === NEW_SUBMISSION_STATES.RECORDING_RECORDING_STOPPED) {
            handleStopCaptureClick()
        }
    }, [newSubmission?.state]);


    const handleStartCaptureClick = React.useCallback(() => {
        // TODO double check if this is called when timer runs out
        if (webcamRef?.current?.stream) {
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                mimeType: "video/webm"
            });
            mediaRecorderRef.current.ondataavailable = handleDataAvailable;
            newSubmission?.startUpload();
            mediaRecorderRef.current.start(5000);
        }
    }, [webcamRef, mediaRecorderRef]);

    const handleDataAvailable = React.useCallback(
        (event) => {
            console.log("Recorded chunks size: " + recordedChunks.current.length);
            const data = event.data
            console.log(`Handle data called ${data.size}`)
            if (data.size > 0) {
                newSubmission?.fileUpload?.addPendingBytes(data);
                if (event.currentTarget?.state === 'inactive') {
                    newSubmission?.fileUpload?.forcePendingBytePartUpload();
                    const blob = new Blob(recordedChunks.current.concat(data), {
                        type: "video/webm"
                    })
                    setUrl(URL.createObjectURL(blob))
                    recordedChunks.current = [];
                } else {
                    recordedChunks.current.push(data);
                }
            }
        },
        []
    );

    const handleStopCaptureClick = React.useCallback(() => {
        if (mediaRecorderRef.current?.state === 'recording') {
            mediaRecorderRef.current?.stop();
            newSubmission?.fileUpload?.finishRecording();
        }
    }, [mediaRecorderRef, webcamRef]);

//TODO: Add a timer that counts down X minutes + add recording indicator in the top left corner
    return (
        <Box
            display={'flex'}
            overflow={'hidden'}
            justifyContent={'center'}
        >
            {overlay}
            {
                (NEW_SUBMISSION_STATES.RECORDING_RECORDING_STOPPED === newSubmission?.state || NEW_SUBMISSION_STATES.AFTER_SUBMIT === newSubmission?.state) && url ?
                    <ReactPlayer controls={NEW_SUBMISSION_STATES.RECORDING_RECORDING_STOPPED === newSubmission?.state}
                                 url={url}/> :
                    <Webcam

                        onUserMedia={(stream) => {
                            MediaSettings.fetchDevices()
                        }}

                        ref={webcamRef}
                        videoConstraints={{
                            height: {min: 400, max: 400},
                            aspectRatio: 16 / 9,
                            deviceId: MediaSettings.cameraId,
                        }}
                        audioConstraints={{
                            deviceId: MediaSettings.microphoneId
                        }}
                        height={400}
                    />
            }
        </Box>
    );
}

export default observer(SubmissionRecordingComponent)
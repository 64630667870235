import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {Box, ButtonBase, IconButton, Typography} from "@material-ui/core";
import {OrganizationContext} from "../models/types/User";
import {Instance} from "mobx-state-tree";
import {ChevronRight} from "@material-ui/icons";

interface OrganizationCardProps {
    organization: Instance<typeof OrganizationContext>,
    onClick: (organization: Instance<typeof OrganizationContext>) => void
}


const useStyles = makeStyles((theme) => ({

    button: {
        width: '100%'
    },

}));

const OrganizationCard: React.FC<OrganizationCardProps> = ({organization, onClick}) => {

    const classes = useStyles();
    return (

        <ButtonBase onClick={() => onClick(organization)} className={classes.button}>
            <Box width={'100%'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography align={'left'} variant="h6">
                        {organization.name}
                    </Typography>
                    <Typography align={'left'} variant="subtitle1" color="textSecondary">
                        {organization.entity_type}
                    </Typography>
                </Box>
                <IconButton color="primary" aria-label={`continue as ${organization.name}`}
                            component="span"><ChevronRight/></IconButton>
            </Box>
        </ButtonBase>
    );
}

export default observer(OrganizationCard)
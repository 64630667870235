import React, {useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {ChallengeListListType} from "../../../../models/types/listTypes/ChallengeListListType";
import ChallengesListEntry from "./ChallengesListEntry";
import {Instance} from "mobx-state-tree";
import ChallengeList from "../../../../models/types/ChallengeList";
import {wrapWithReduxStore} from "../../../../util/Utils";

interface ChallengesListViewProps {

}


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    }
}));


const ChallengesListView: React.FC<ChallengesListViewProps> = ({}) => {
    const classes = useStyles();

    const challengeListList = useRef(ChallengeListListType.create());
    useEffect(() => {
        wrapWithReduxStore(challengeListList.current)
        challengeListList.current.fetch()
    }, []);

    const renderChallengeListEntry = (challengeList?: Instance<typeof ChallengeList>, index?: number) =>
        (<Grid key={challengeList?.id ?? index} item xs={12}>
            <ChallengesListEntry challengeList={challengeList}/>
        </Grid>);

    return (
        <Container className={classes.container}>
            <Grid container spacing={3}>
                {challengeListList.current.results.length > 0 ? challengeListList.current.results.map(renderChallengeListEntry)
                    :
                    new Array(3).fill(undefined).map(renderChallengeListEntry)
                }
            </Grid>
        </Container>
    );
}

export default observer(ChallengesListView);
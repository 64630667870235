import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ChallengeCard from "../../../../components/ChallengeCard/ChallengeCard";
import {Instance} from "mobx-state-tree";
import ChallengeList from "../../../../models/types/ChallengeList";
import {observer} from "mobx-react";

interface ChallengeListSectionProps {
    challengeList?: Instance<typeof ChallengeList>
}


const useStyles = makeStyles((theme) => ({}));

const ChallengeListSection: React.FC<ChallengeListSectionProps> = ({challengeList}) => {
    if (challengeList?.hasUpcomingChallenges) {
        return <Grid container spacing={3}>
            {challengeList?.displayChallenges.map(challenge => <Grid
                key={challenge.id}
                item
                xs={12}
                sm={6}
                md={4}>
                <ChallengeCard challenge={challenge}/>
            </Grid>)}
        </Grid>
    }
    return !challengeList ? <Grid container spacing={3}>{new Array(3).fill(0).map((a, index) => <Grid
        key={index}
        item
        xs={12}
        sm={6}
        md={4}>
        <ChallengeCard/>
    </Grid>)}</Grid> : null
}

export default observer(ChallengeListSection)
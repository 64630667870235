import axios, {AxiosInstance} from "axios";
import MainStore from "../models/stores/MainStore";
import StageType from "./StageType";

let axiosInstance: AxiosInstance;


export let accessTokenUtil: { get?: (() => Promise<String>) | undefined } = {};

async function getHeader() {
    if (accessTokenUtil.get) {
        return {Authorization: `Bearer ${await accessTokenUtil.get()}`}
    }
    return {}
}

export async function getAxiosInstance() {

    if (axiosInstance) {
        axiosInstance.defaults = {
            headers: await getHeader()
        }
    } else {
        axiosInstance = axios.create({
            baseURL: StageType.baseUrl,
            headers: await getHeader()
        });
        axiosInstance.interceptors.response.use((response) => response, (error) => {
            if (error?.response?.status === 403) {
                MainStore.history?.push('/postLogin');
            }
            throw error;
        });
    }
    return axiosInstance
}
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useAlert} from "../models/hooks/useAlert";

interface CustomAlertProps {

}


const useStyles = makeStyles((theme) => ({}));

const CustomAlert: React.FC<CustomAlertProps> = ({}) => {

    const Alert = useAlert()

    function handleClose() {
        Alert.close()
    }

    return (
        <Dialog
            open={!!Alert?.show}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{Alert.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {Alert.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => Alert.close()} color="primary">
                    {Alert.cancelText}
                </Button>
                <Button onClick={() => Alert.submit()} color="primary" autoFocus>
                    {Alert.submitText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default observer(CustomAlert)
import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {Popover} from "@material-ui/core";
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state';
import NotificationList from "./components/NotificationList";
import {useNotifications} from "../../models/hooks/useNotifications";

interface NotificationButtonProps {

}


const useStyles = makeStyles((theme) => ({}));

const InnerNotificationButton = observer((props: any) => {
    const Notification = useNotifications()
    return <IconButton color="inherit" {...bindTrigger(props.popupState)}>
        <Badge badgeContent={Notification.unreadNotificationsCount} color="secondary">
            <NotificationsIcon/>
        </Badge>
    </IconButton>
});


const NotificationButton: React.FC<NotificationButtonProps> = ({}) => {

    const Notification = useNotifications()
    useEffect(() => {
        Notification.fetchNotifications();
    }, [])

    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState: any) => (
                <div>
                    <InnerNotificationButton popupState={popupState}/>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <NotificationList/>
                    </Popover>
                </div>
            )}
        </PopupState>
    );
}

export default observer(NotificationButton)
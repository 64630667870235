import {createContext, useContext} from 'react';
import {flow, Instance, types} from "mobx-state-tree";
import {getAxiosInstance} from "../../util/AxiosUtil";
import Notification from '../types/Notification'


export const NotificationListType = types.model("NotificationList", {
    count: 0,
    notifications: types.array(Notification),
    unreadCount: 0,
}).views(self => ({
    get unreadNotificationsCount() {
        return self.unreadCount
    },
    get list() {
        return self.notifications
    },
    get newestUnreadNotifications() {
        return self.notifications.filter(notification => !notification.seen).slice(0, 5)
    },
    get hasUnreadNotifications() {
        return self.unreadCount > 0
    },
})).views(self => ({
    get hasMoreNotifications() {
        return self.newestUnreadNotifications.length < self.count
    },
})).actions(self => ({
    fetchNotifications: flow(function* fetchNotification() {
        try {
            const response = (yield (yield getAxiosInstance()).get('users/currentuser/notifications')).data
            self.notifications = response.results
            self.unreadCount = response.new
        } catch (e) {
            console.error("Failed to get notifications", e)
        }
    }),
    markNotificationAsRead(notification: Instance<typeof Notification>) {
        self.unreadCount--;
        notification.toggleReadStatus()
    }
}))

export const NotificationList = NotificationListType.create();

export const NotificationListContext = createContext<typeof NotificationList>(NotificationList);


export const useNotifications = (): typeof NotificationList => useContext(NotificationListContext);

import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {
    Box,
    Button,
    Dialog,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import theme from "../../themes/defaultTheme";
import {parse} from 'papaparse';
import {validateEmail} from "../../util/Utils";
import HeaderSelection from "./HeaderSelection";

export interface headerType {
    key: string
    display: string
}

interface CsvUploadModalProps {
    initOpened?: boolean,
    csvFile?: File,
    columns?: headerType[],
    onClose: () => void,
    onUpload: (file: File, mapping: string[]) => any
}


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));


const CsvUploadModal: React.FC<CsvUploadModalProps> = ({
                                                           onClose,
                                                           initOpened = false,
                                                           csvFile,
                                                           onUpload,
                                                           columns = [{
                                                               key: 'email',
                                                               display: 'Email'
                                                           }, {
                                                               key: 'lastname',
                                                               display: 'Last Name'
                                                           }, {key: 'firstname', display: 'First Name'}]
                                                       }) => {

    const [open, setOpen] = useState<boolean>(initOpened);
    const [rows, setRows] = useState<any>([]);
    const [headers, setHeaders] = useState([...columns]);
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        setOpen(initOpened)
    }, [initOpened]);

    useEffect(() => {
        if (csvFile) {
            parse<string[]>(csvFile, {
                preview: 1,
                complete: function (results) {
                    if (results?.data?.length > 0) {
                        let header = false;
                        let emailIndex = results.data[0].findIndex(entry => validateEmail(entry))
                        if (emailIndex < 0) {
                            header = true
                        }

                        parse<string[]>(csvFile, {
                            preview: header ? 6 : 5,
                            complete: function (results) {
                                if (header) {
                                    results.data.splice(0, 1)
                                }
                                setRows(results.data)
                            }
                        })
                    }
                }
            });
        }
    }, [csvFile]);


    const setHeaderByIndex = (index: number, header: headerType) => {
        const newHeaders = [...headers];
        newHeaders[index] = header;
        setHeaders(newHeaders);
    }
    return (
        <Dialog
            open={initOpened}
            onClose={() => {
                setOpen(false)
                onClose()
            }}
            aria-labelledby="csv upload modal"
            aria-describedby="allows you to upload a csv"
            fullScreen={fullScreen}
            fullWidth
            maxWidth={'md'}

        >
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header, index) => <HeaderSelection
                                            error={headers.filter(arrayHeader => arrayHeader.key === header.key).length > 1}
                                            key={header.key + index} headers={columns}
                                            selectedHeader={headers[index]}
                                            onChange={(header) => setHeaderByIndex(index, header)}/>)}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows?.length > 0 && rows.map((row: any[]) => <TableRow
                                        key={row[0]}>{row?.map((col, index) =>
                                        <TableCell key={col + '_' + index}>{col}</TableCell>)}
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body2'}>
                            Please make sure that you selected the header correctly
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container justify={'flex-end'} spacing={1}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color={"default"}
                                onClick={() => onClose()}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color={"secondary"}
                                onClick={() => {
                                    if (csvFile) {
                                        onUpload(csvFile, headers.map(header => header.key))
                                    }
                                    onClose()
                                }}
                            >
                                Invite
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
}

export default observer(CsvUploadModal)
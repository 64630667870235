import React, {useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import GridWithListType from "../../../../components/Tables/GridWithListType";
import {AdminSubmissionListType} from "../../../../models/types/listTypes/SubmissionListType";
import useQuery from "../../../../models/hooks/useQuery";
import TableHeaderWithFilter from "../../../../components/TableHeaderWithFilter";
import Utils from "../../../../util/Utils";


interface SubmissionsListViewProps {

}


const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    }
}));


const SubmissionList = AdminSubmissionListType.create()

Utils.wrapWithReduxStore(SubmissionList)

const SubmissionsListView: React.FC<SubmissionsListViewProps> = ({}) => {
    const classes = useStyles();
    const submissionList = useRef(SubmissionList);

    const query = useQuery();

    useEffect(() => {
        const filter = Array.from(query.entries());
        let filterKey = undefined;
        if (filter?.length > 0) {
            filterKey = filter[0][0]
        }
        submissionList.current.setFilterByKey(filterKey)
        submissionList.current.fetch()
    }, []);


    return (
        <Container className={classes.container}>
            <TableHeaderWithFilter list={submissionList.current} text={"All Submissions"}/>
            <GridWithListType
                listType={submissionList.current}
                onCellClick={(a, b) => b.preventDefault()}
                onRowClick={(submission, e) => {
                    e.preventDefault()

                }}
            />
        </Container>
    );
}

export default observer(SubmissionsListView)
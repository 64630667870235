import React, {createContext, FC, ReactNode, useEffect} from 'react';
import {RootStore} from '../stores/RootStore';
import {useHistory} from "react-router-dom";


export const StoreContext = createContext<RootStore>({} as RootStore);
export type StoreComponent = FC<{
    store: RootStore;
    children: ReactNode;
}>;

export const StoreProvider: StoreComponent = ({
                                                  children,
                                                  store
                                              }) => {

    const history = useHistory();

    useEffect(() => {
        //Used so that we can access history in certain edge cases. DO NOT ABUSE THIS history field
        store.MainStore.setHistory(history)
    }, []);


    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    )
}

import React, {useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useSubmission} from "../hooks/SubmissionProvider";
import Grid from "@material-ui/core/Grid";
import ChipInput from "../../../components/ChipInput";
import {MailOutline} from "@material-ui/icons";
import {InputAdornment} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {observer} from "mobx-react";
import {validateEmail} from "../../../util/Utils";
import {useToast} from "../../../models/hooks/useToast";

interface ReviewRequesterProps {
    maximumAmountOfRequester?: number
}


const useStyles = makeStyles((theme) => ({}));


const ReviewRequester: React.FC<ReviewRequesterProps> = ({maximumAmountOfRequester = 5}) => {

    const submission = useSubmission();
    const Toast = useToast();
    const currentInputText = useRef("");

    const checkEmail = (chip: string) => {
        if (!validateEmail(chip)) {
            Toast.showSnack('Please enter a valid email')
            return false
        }
        return true
    };

    return (
        <Grid container spacing={1}>
            <Grid xs={12} item>
                <Typography variant="subtitle1">
                    ADD YOUR OWN REVIEWERS
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <ChipInput
                    onUpdateInput={(e: any) => currentInputText.current = e?.target?.value}
                    onBeforeAdd={checkEmail}
                    icon={<InputAdornment position="start">
                        <MailOutline/>
                    </InputAdornment>}
                    onChange={(chips => {
                        submission?.setFeedbackRequestEmails(chips)
                    })}
                    variant={"outlined"}
                    fullWidth
                />
            </Grid>
            <Grid container justify="flex-end" xs={12} item>
                <Button

                    disableElevation
                    size={'large'}
                    variant="contained"
                    color={'secondary'}
                    onClick={() => {
                        if (currentInputText.current) {
                            if (checkEmail(currentInputText.current)) {
                                submission?.addFeedbackRequestEmail(currentInputText.current)
                            } else {
                                return
                            }
                        }
                        submission?.requestFeedbackFromAll()
                    }}
                >
                    Send
                </Button>
            </Grid>
            {submission?.hasRequestedReviewers &&
            <Grid container xs={12} spacing={2} item>
                <Grid item xs={12}><Typography>Already added:</Typography></Grid>
                {submission.requestedReviewers.map(reviewer => (
                    <Grid item xs={12}
                          key={reviewer.id}>
                        <Chip
                            icon={<MailOutline/>}
                            label={reviewer.email}
                            // onDelete={handleDelete}
                            // deleteIcon={<DoneIcon />}
                        />
                    </Grid>
                ))}
            </Grid>
            }
        </Grid>
    );
}

export default observer(ReviewRequester)
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {useSubmission} from "../../hooks/SubmissionProvider";
import {Skeleton} from "@material-ui/lab";

interface NewSubmissionTipsSidePanelProps {
    button?: React.ReactNode,
    backgroundColor?: string
}


const useStyles = makeStyles((theme) => ({
    box: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    text: {
        whiteSpace: 'pre-line'
    }
}));

const NewSubmissionTipsSidePanel: React.FC<NewSubmissionTipsSidePanelProps> = ({button = null, backgroundColor}) => {

    const classes = useStyles();

    const submission = useSubmission();
    const challenge = submission?.challenge

    return (
        <Box height={'100%'} display={'flex'} className={classes.box} flexDirection="column"
             justifyContent={'space-between'} style={backgroundColor ? {backgroundColor} : undefined}>
            <Box flexGrow={1} p={3}>
                <Typography gutterBottom variant={'h5'}>Exercise Description</Typography>
                <Typography className={classes.text}>
                    {challenge?.description ||
                    <React.Fragment><Skeleton width={150}/><Skeleton width={110}/><Skeleton width={120}/><br/><Skeleton
                        width={130}/><Skeleton
                        width={120}/></React.Fragment>}
                </Typography>
            </Box>
            {button}
        </Box>
    );
}

export default observer(NewSubmissionTipsSidePanel)
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ChallengeDetails from "../../components/ChallengeDetails";
import Typography from "@material-ui/core/Typography";

interface NewSubmissionTipsSidePanelProps {
    button?: React.ReactNode,
    backgroundColor?: string
}


const useStyles = makeStyles((theme) => ({
    box: {
        color: theme.palette.secondary.contrastText
    },
}));

const NewSubmissionTipsSidePanel: React.FC<NewSubmissionTipsSidePanelProps> = ({button = null}) => {

    const classes = useStyles();

    return (
        <Box height={'100%'} display={'flex'} p={4} flexDirection="column"
             justifyContent={'space-between'}>
            <Typography component={'span'} color={'textPrimary'}>
                <ChallengeDetails/>
            </Typography>
            {button}
        </Box>
    );
}

export default observer(NewSubmissionTipsSidePanel)
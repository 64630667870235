import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import {Paper} from "@material-ui/core";

interface SubmissionContentPaperProps {
    VideoComponent: React.ReactNode,
    SidePanelComponent: React.ReactNode,
    BottomPanelComponent: React.ReactNode,
    bottomPanelPadding?: number
}


const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        flexDirection: 'column'
    },
    videoBG: {
        backgroundColor: theme.palette.grey[600],
        minHeight: 360,
        position: 'relative'
    },
}));

const SubmissionContentPaper: React.FC<SubmissionContentPaperProps> = ({
                                                                           VideoComponent,
                                                                           SidePanelComponent,
                                                                           BottomPanelComponent,
                                                                           bottomPanelPadding = 4
                                                                       }) => {
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Box pb={4}>
                <Grid container>
                    <Grid item xs={12} md={8} className={clsx(classes.videoBG)}>
                        {VideoComponent}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {SidePanelComponent}
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={bottomPanelPadding}>
                            {BottomPanelComponent}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}

export default observer(SubmissionContentPaper)
import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
import {MuiThemeProvider} from "@material-ui/core";
import theme from './themes/defaultTheme';
import {Integrations} from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import StageType from "./util/StageType";
import { CloudimageProvider } from 'react-cloudimage-responsive';

const cloudimageConfig = {
    token: 'amsbbcglmq'
};

Sentry.init({
    dsn: "https://ec203e0e49d64d61bcdee017a5c95166@o316025.ingest.sentry.io/5889214",
    integrations: [new Integrations.BrowserTracing()],
    environment: StageType.stage,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <MuiThemeProvider theme={theme}>
            <CloudimageProvider config={cloudimageConfig}>
            <Router/>
            </CloudimageProvider>
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
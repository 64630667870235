import {createContext, FC, ReactNode, useContext} from 'react';

const ProtectedRouteContext = createContext<boolean>(false);

export type ProtectedRouteContextProps = FC<{
    children: ReactNode;
}>;

/**
 * Can be used to figure out if the route is a protected route or not
 */
export const useProtectedRoute = (): boolean => useContext(ProtectedRouteContext);

export const ProtectedRouteProvider: ProtectedRouteContextProps = ({children}) => {

    return (<ProtectedRouteContext.Provider value={true}> {children} </ProtectedRouteContext.Provider>)
}

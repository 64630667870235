import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import theme from "../../../../themes/defaultTheme";
import {ListType, ShortListViewType} from '../../../../models/types/listTypes/ListType'
import {IAnyType, Instance} from "mobx-state-tree";
import {Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {useHistory} from "react-router-dom";

interface ListPanelProps<T extends IAnyType> {
    list: Instance<ListType<T>>
    label: string
    onItemClicked?: (shortListView: ShortListViewType) => void
}


const useStyles = makeStyles((theme) => ({

    list: {
        width: '100%',
        paddingBottom: 0,
        paddingTop: 0
    },
    listItem: {
        wordBreak: 'break-word'
    }
}));

function ListPanel<T extends IAnyType>({label, list, onItemClicked}: ListPanelProps<T>) {
    const classes = useStyles();
    const color = theme.palette.primary.main
    const history = useHistory();
    const rows = list?.shortListView
    return (
        <div>
            <Typography variant={'subtitle2'}>
                {label}
            </Typography>
            <Card>
                <Box>
                    <List className={classes.list}>
                        {rows.map((shortListView: ShortListViewType, index) =>
                            <ListItem
                                classes={{root: classes.listItem}}
                                dense button={!!onItemClicked as true /* stupid workaround for TS definition*/}
                                onClick={() => {
                                    if (onItemClicked) {
                                        onItemClicked(shortListView)
                                    }
                                }} key={index}
                                divider>
                                <ListItemAvatar>
                                    <Avatar src={shortListView?.picture} alt="Submitter Name"/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={shortListView ? shortListView.title : <Skeleton/>}
                                    secondary={shortListView ? shortListView.subtitle : <Skeleton/>}
                                />
                            </ListItem>
                        )}
                        <ListItem
                            button={list.shortListView.length > 0 as true /* stupid workaround for TS definition*/}
                            onClick={() => {
                                if (list.shortListView.length > 0) {
                                    history.push(list.seeAllClickURL + list.filtersAsURLParamString)
                                }
                            }}>
                            <ListItemText
                                primary={list.shortListView.length > 0 ? `See all ${list.count} ${label}` : `No ${label}`}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Card>
        </div>
    );
}

export default observer(ListPanel)
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import SubmissionHeader from "../../SubmissionPage/components/SubmissionHeader";
import SubmissionContentPaper from "../../SubmissionPage/components/SubmissionContentPaper";
import ViewSubmissionSidePanel from "./ViewSubmissionSidePanel";
import {useSubmission} from "../../SubmissionPage/hooks/SubmissionProvider";
import ReactPlayer from "react-player";
import Grid from "@material-ui/core/Grid";
import ReviewRequester from "../../SubmissionPage/components/ReviewRequester";
import {observer} from 'mobx-react';
import ChallengeDetailSection from "../../SubmissionPage/components/ChallengeDetailSection";
import FeedbackComponentView from "./FeedbackComponentView";
import {useLocation} from "react-router-dom";
import {RouteState} from "../../../components/Authentication/ProtectedRoute";
import {Check} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Lottie from 'react-lottie-player'
import animationData from '../../../animations/7379-gears-animation.json'
import {Typography} from "@material-ui/core";


interface ViewSubmissionViewProps {

}

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    blueBG: {
        backgroundColor: theme.palette.primary.main,
        minHeight: '100vh'
    },
    actionButton: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.caribbean
    },
    disabledButton: {
        backgroundColor: `${theme.palette.common.caribbean} !important`,
        color: '#fff !important'
    },
    loadingScreen: {
        // backgroundColor: theme.palette.grey[600],
    }
}));

const ViewSubmissionView: React.FC<ViewSubmissionViewProps> = ({}) => {
    const classes = useStyles();
    const submission = useSubmission();
    const location = useLocation<RouteState>()
    const isAdmin = location.state.isAdmin;

    let sidePanel = <ViewSubmissionSidePanel button={<Button
        fullWidth
        disableElevation
        classes={{root: classes.actionButton, disabled: classes.disabledButton}}
        size={'large'}
        variant="contained"
        disabled={!isAdmin}
        color={'secondary'}
        startIcon={isAdmin ? <Check/> : undefined}
        onClick={() => {
            submission?.toggleDoneState()
        }}
    >
        {submission?.completed ? 'Completed!' : isAdmin ? "Mark exercise as completed" : "Not yet completed"}
    </Button>}/>

    return (
        <Box className={classes.blueBG}>
            <div className={classes.appBarSpacer}/>
            <Container maxWidth='lg'>
                <SubmissionHeader/>
                <SubmissionContentPaper
                    VideoComponent={
                        submission?.finishedTranscoding ?
                            <Box
                                display={'flex'}
                                overflow={'hidden'}
                                justifyContent={'center'}
                            >
                                <ReactPlayer controls url={submission?.mp4VideoLink}/>
                            </Box> :
                            submission ?
                                <Box
                                    className={classes.loadingScreen}
                                    flexDirection={'column'}
                                    height={'100%'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    color={'#fff'}
                                >
                                    <Lottie
                                        loop
                                        animationData={animationData}
                                        play
                                        style={{width: '50%', height: '50%'}}
                                    />
                                    <Typography>
                                        video still processing
                                    </Typography>
                                </Box> :
                                null
                    }
                    SidePanelComponent={sidePanel}
                    bottomPanelPadding={0}
                    BottomPanelComponent={
                        <Box pl={4} pr={4} pt={4}>
                            <Grid container spacing={2}>
                                <Grid container alignContent={'flex-start'} item md={4} xs={12}>
                                    <Grid item xs={12}>
                                        <ReviewRequester/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box mt={4}>
                                            <ChallengeDetailSection full/>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <FeedbackComponentView allowNewFeedback={isAdmin}/>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                />
            </Container>
        </Box>
    );
}

export default observer(ViewSubmissionView);
import {connectReduxDevtools} from "mst-middlewares";
import StageType from "./StageType";


export const wrapWithReduxStore = (instance: any) => {
    if (StageType.isDev) {
        connectReduxDevtools(require("remotedev"), instance)
    }
}

export function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export default {
    wrapWithReduxStore,
    validateEmail
}
import {flow, getParent, types} from "mobx-state-tree";
import {getAxiosInstance} from "../../util/AxiosUtil";

export const NotificationMeta = types.model('NotificationMeta', {
    feedbackId: "",
    submissionId: ""
})

const NotificationMetaProcessed = types.snapshotProcessor(NotificationMeta, {
    preProcessor(snapshot: any): typeof NotificationMeta["CreationType"] {
        if (snapshot) {
            try {
                if (snapshot.feedback_id) {
                    snapshot.feedbackId = snapshot.feedback_id;
                }
                if (snapshot.submission_id) {
                    snapshot.submissionId = snapshot.submission_id;
                }
                if (snapshot.feedback) {
                    snapshot.feedbackId = snapshot.feedback.id
                }
                if (snapshot.submission) {
                    snapshot.submissionId = snapshot.submission.id
                }
                return snapshot;
            } catch (e) {
                console.error('error while transforming notificationmeta', e);
                return snapshot;
            }
        }
        return snapshot
    }
})

export const Notification = types.model("Notification", {
    id: types.identifier,
    title: types.string,
    message: types.string,
    metadata: types.maybe(NotificationMetaProcessed),
    seen: false
})
    .views(self => ({
        get forwardUrl(): string | undefined {
            let forwardUrl = '';
            if (self.metadata) {
                if (self.metadata.submissionId) {
                    forwardUrl = `/student/submission/${self.metadata.submissionId}`
                    if (self.metadata.feedbackId) {
                        forwardUrl += `?feedback=${self.metadata.feedbackId}`
                    }
                    return forwardUrl;
                }
            }
            return undefined
        }
    }))
    .actions((self) => ({
        toggleReadStatus: flow(function* toggleReadStatus() {
            if (!self.seen) {
                try {
                    (yield getAxiosInstance()).post('users/currentUser/notifications/seen', {notification_id: self.id})
                } catch (e) {
                    console.error('Error while toggling readstatus');
                }
            }
            self.seen = !self.seen
        })
    }))

export default Notification
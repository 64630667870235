import {applySnapshot, flow, Instance, SnapshotIn, types} from "mobx-state-tree";
import Challenge from "./Challenge";
import {getAxiosInstance} from "../../util/AxiosUtil";
import MainStore from "../stores/MainStore";
import theme from "../../themes/defaultTheme";


const ScalableImageType = types.model('ScalableImageType', {
    url: types.string,
}).views(self => ({
    get scaledUrl(): string {
        return `https://amsbbcglmq.cloudimg.io/v7/${self.url.replace('https://', '')}?w=1000`
    }
}))


const BaseChallengeList = types.model("ChallengeList", {
    id: types.identifier,
    description: types.maybe(types.string),
    title: types.maybe(types.string),
    challenges: types.array(Challenge),
    image_url: "",
    image: types.maybe(ScalableImageType),
    selectedChallenge: types.maybe(types.reference(Challenge)),
    color: theme.palette.grey[300],
    goal: ''
})
    .views(self => ({
        get sortedChallenges(): Instance<typeof Challenge>[] {
            return self.challenges.slice().sort((challenge, nextChallenge) => challenge.position - nextChallenge.position)
        }
    }))
    .views((self) => ({
        get lockedChallenges(): Instance<typeof Challenge>[] {
            return self.sortedChallenges.filter(challenge => !challenge.done).slice(1, self.sortedChallenges.filter(challenge => !challenge.done).length)
        },
        get hasUpcomingChallenges(): boolean {
            return true
        },
        get mainChallenge(): Instance<typeof Challenge> | undefined {
            return self.sortedChallenges.find(challenge => !challenge.done)
        },
        get challengesCount(): number {
            return self.sortedChallenges.length
        },
        get doneChallengesCount(): number {
            return self.sortedChallenges.filter(challenge => challenge.done).length
        },
        get notDoneChallengesCount(): number {
            return self.sortedChallenges.filter(challenge => !challenge.done).length
        },
        get progressInPercent(): number {
            return self.sortedChallenges.filter(challenge => challenge.done).length / self.sortedChallenges.length * 100
        },
        get isInWork(): number {
            return self.sortedChallenges.filter(challenge => challenge.done).length / self.sortedChallenges.length * 100
        },
        fetchChallengeListDetails: flow(function* fetchChallengeListDetails() {
            try {
                const remoteChallengeList = (yield (yield getAxiosInstance()).get(`/organizations/${MainStore.organizationId}/users/${MainStore.userId}/challengelists/${self.id}`)).data
                applySnapshot(self, {...self, ...remoteChallengeList})
            } catch (error) {
                console.error("Failed to fetch challengelist", error)
            }
        }),
    })).views(self => ({
        get isDone(): boolean {
            return self.doneChallengesCount >= self.challengesCount
        },
        get inProgress(): boolean {
            return !!self.challenges.find(challenge => challenge.hasSubmissions)
        },
        get displayChallenges(): Instance<typeof Challenge>[] {
            let challengeIndex = self.sortedChallenges.findIndex(challenge => !challenge.done)
            if (challengeIndex === 0 || challengeIndex === -1) {
                return self.sortedChallenges.slice(0, 3)
            } else if (challengeIndex === self.sortedChallenges.length - 1) {
                return self.sortedChallenges.slice(challengeIndex - 2)
            }
            return self.sortedChallenges.slice(challengeIndex - 1, challengeIndex + 2)
        },
    })).actions(self => ({
        selectChallenge(challenge?: Instance<typeof Challenge>) {
            self.selectedChallenge = challenge
        },
        afterCreate() {
            if (self.challengesCount === 0) {
                self.fetchChallengeListDetails()
            }
        }
    }))


export const ChallengeList = types.snapshotProcessor(BaseChallengeList, {
    preProcessor(snapshot: SnapshotIn<typeof BaseChallengeList>): typeof BaseChallengeList["CreationType"] {
        if (snapshot) {
            try {
                if (snapshot.image_url) {
                    snapshot.image = {url: snapshot.image_url}
                }
                return snapshot;
            } catch (e) {
                console.error('error while transforming ChallengeList snapshot', e);
                return snapshot;
            }
        }
        return snapshot
    }
})


export const ChallengeListWrapper = types.model("ChallengeListWrapper", {
    id: types.string,
    challengeList: types.maybe(ChallengeList)
}).actions(self => ({
    fetchChallengeList: flow(function* fetchChallengeList() {
        try {

            self.challengeList = (yield (yield getAxiosInstance()).get(`/organizations/${MainStore.organizationId}/users/${MainStore.userId}/challengelists/${self.id}`)).data
        } catch (error) {
            console.error("Failed to fetch challengelist", error)
        }
    }),
})).actions(self => ({
    afterCreate() {
        if (!self.challengeList) {
            self.fetchChallengeList()
        }
    }
}))

export default ChallengeList
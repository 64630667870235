import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {useMediaSettings} from "../models/hooks/useMediaSettings";

interface MediaDevicePickerProps {
    deviceType: MediaDeviceKind
}


const useStyles = makeStyles((theme) => ({
    formControl: {
        width:'100%',
        maxWidth: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const MediaDevicePicker: React.FC<MediaDevicePickerProps> = ({deviceType}) => {

    const classes = useStyles();

    const MediaSettings = useMediaSettings()

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const deviceId = event.target.value as string;
        MediaSettings.setSelectedDevice(deviceId, deviceType);
    };


    const label = deviceType === 'videoinput' ? "Select Camera" : deviceType === 'audioinput' ? 'Select Microphone' : 'Select Device';

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="media-device-picker-label">{label}</InputLabel>
            <Select
                labelId="media device picker"
                id="media device picker "
                value={deviceType === "audioinput" ? MediaSettings.microphoneId : MediaSettings.cameraId}
                onChange={handleChange}
                label={label}
            >
                {MediaSettings.devicesByType(deviceType).map(device => <MenuItem
                    key={device.deviceId}
                    value={device.deviceId}>{device.label}</MenuItem>)}
            </Select>
        </FormControl>
    );
}

export default observer(MediaDevicePicker)
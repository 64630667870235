import {_NotCustomized, IAnyType, ISnapshotProcessor, SnapshotIn, types} from "mobx-state-tree";

type AllowNullIfUndefined<T> = {
    [P in keyof T]: undefined extends T[P] ? T[P] | null : T[P];
} & {
    [key: string]: any
}

export function NullFilterProcessor<ModelType extends IAnyType>(model: ModelType): ISnapshotProcessor<ModelType, AllowNullIfUndefined<typeof model["CreationType"]>, _NotCustomized> {
    return types.snapshotProcessor(model,
        {
            //Here we transform the challenge from the backend to the model in the way we need it
            preProcessor(snapshot: SnapshotIn<ModelType>): typeof model["CreationType"] {
                if (snapshot) {
                    try {
                        return cleanObj(snapshot);
                    } catch (e) {
                        console.error('error while transforming Allowing Null on type, will now just use the default snapshot', e);
                        return snapshot;
                    }
                }
                return snapshot
            }
        }
    )
}

export function cleanObj(obj: any) {
    for (const propName in obj) {
        if (obj.hasOwnProperty(propName) && (obj[propName] === null || obj[propName] === undefined)) {
            delete obj[propName];
        }
    }
    return obj
}
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BorderLinearProgress from "./BorderLinearProgress";
import Box from "@material-ui/core/Box";
import ChallengeList from "../models/types/ChallengeList";
import {Instance} from "mobx-state-tree";
import theme from "../themes/defaultTheme";

interface ChallengeListProgressProps {
    challengeList?: Instance<typeof ChallengeList>,
    contrastText?: boolean
}


const useStyles = makeStyles((theme) => ({
    progressHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const ChallengeListProgress: React.FC<ChallengeListProgressProps> = ({challengeList, contrastText = false}) => {
    const classes = useStyles();
    return (
        <Box
            color={(contrastText && challengeList) ? theme.palette.getContrastText(challengeList.color) : theme.palette.primary.main}>
            <div className={classes.progressHeader}>
                <Typography
                    variant={"subtitle2"}>Completed Exercises</Typography>
                <Typography
                    variant={"subtitle2"}>{challengeList?.doneChallengesCount ?? 0} of {challengeList?.challengesCount ?? 0}</Typography>
            </div>
            <BorderLinearProgress variant="determinate" value={challengeList?.progressInPercent ?? 0}/>
        </Box>
    );
}

export default observer(ChallengeListProgress)
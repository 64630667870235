import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SectionHeader from "../../../../components/SectionHeader";
import {Divider} from "@material-ui/core";
import ChallengeListProgress from "../../../../components/ChallengeListProgress";
import theme from "../../../../themes/defaultTheme";
import ChallengeListSection from "./ChallengeListSection";
import Container from "@material-ui/core/Container";
import {useChallengeList} from "../../../../models/hooks/ChallengeListProvider";
import BackToDashboardButton from "../../../SubmissionPage/components/BackToDashboardButton";
import ChallengesListCard from "../../../../components/ChallengeListCard/ChallengesListCard";
import ChallengeCardButton from "../../../../components/ChallengeCard/components/ChallengeCardButton";

interface ChallengeListViewProps {

}


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }
}));

const ChallengeListView: React.FC<ChallengeListViewProps> = ({}) => {
    const classes = useStyles();

    const {challengeList} = useChallengeList()
    return (
        <Container className={classes.container}>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <BackToDashboardButton/>
                </Grid>
                <Grid item xs={12}>
                    <SectionHeader boxColor={challengeList?.color} text={challengeList?.title}/>
                </Grid>
                <Grid item xs={12}>
                    <ChallengesListCard challengeList={challengeList}>
                        <ChallengeCardButton challenge={challengeList?.mainChallenge} isHeader/>
                    </ChallengesListCard>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <ChallengeListProgress challengeList={challengeList}/>
                </Grid>
                <Grid item xs={12}>
                    <SectionHeader text={'Upcoming Exercises'}
                                   boxColor={theme.palette.common.berry}/>
                </Grid>
                <Grid item xs={12}>
                    <ChallengeListSection challengeList={challengeList}/>
                </Grid>

            </Grid>
        </Container>
    );
}

export default observer(ChallengeListView)
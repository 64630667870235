import React from 'react';
import {observer} from 'mobx-react';
import {useHistory} from "react-router-dom";
import {AppState, Auth0Provider} from "@auth0/auth0-react";
import StageType from "../../util/StageType";

interface Auth0ProviderWithHistoryProps {

}

const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({children}) => {
    const history = useHistory();
    const onRedirectCallback = async (appState?: AppState) => {
        history.push('/postLogin', {returnTo: appState?.returnTo});
    };

    if (StageType.isPrd) {
        return (
            <Auth0Provider
                domain={'auth.uppercampus.com'}
                clientId={'u2agsLBImQLP6DEwHdAK3LjoukS2Lwsv'}
                redirectUri={`${window.location.origin}/postLogin`}
                cacheLocation={'localstorage'}
                useRefreshTokens={true}
                audience={'PdcSbHXZnDIwRYaSyxGDa1AlEndxLmTd'}
                onRedirectCallback={onRedirectCallback}
            >
                {children}
            </Auth0Provider>
        );
    }
    return (
        <Auth0Provider
            domain={'uppercampus-test.us.auth0.com'}
            clientId={'ej3Da1G0NaoEgaNX6Gugxq44AOy19rJb'}
            redirectUri={`${window.location.origin}/postLogin`}
            cacheLocation={'localstorage'}
            useRefreshTokens={true}
            audience={'Jga3PusBwThbvxLV67a9jrBv0qtDVzij'}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
}


export default observer(Auth0ProviderWithHistory)
import React from 'react';
import {observer} from 'mobx-react';
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import {LinearProgress} from "@material-ui/core";
import {LinearProgressProps} from "@material-ui/core/LinearProgress/LinearProgress";

export interface BorderLinearProgressProps extends LinearProgressProps {

}

const BorderLinearProgress: React.ComponentType<BorderLinearProgressProps> = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 15,
            borderRadius: 7.5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: theme.palette.secondary.main,
        },
    }),
)(LinearProgress);

export default observer(BorderLinearProgress)
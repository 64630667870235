import {getParent, Instance, onSnapshot, types} from "mobx-state-tree";
import ChallengeList from "./ChallengeList";

export const SectionData = types.model({
    icon: types.string,
    value: types.union(types.string, types.number)
})

export const SectionMeta = types.model({
    title: types.string,
    section_data: types.array(SectionData)
})

export const ChallengeTypeWithoutProcessors = types.model("Challenge", {
    id: types.identifier,
    title: types.string,
    description: "",
    submissionIds: types.array(types.string),
    //TODO: Challenge need to provide the tips - https://trello.com/c/aV8rQ6so/121-challenges-have-an-optional-array-of-tips-strings
    tips: types.optional(types.array(types.string), [
        'You will have :60 to prepare for your video.',
        'Check that camera is on, centered and that audio is working.',
        'Try to have no background noises or visual distractions.',
        'Try to keep videos under 3 minutes.',
        'Address the question directly and succinctly.',
        'Be natural, confident and excited to share your story.'
    ]),
    category: "general",
    picture: "https://source.unsplash.com/random/600x800",
    numberOfSubmissions: 0,
    position: 0,
    section_meta: types.array(SectionMeta),
    done: false
}).views(self => ({
    get isLocked(): boolean {
        const parent = getParent<typeof ChallengeList>(self, 2);
        return parent.lockedChallenges.filter(challenge => {
            return challenge.id === self.id
        }).length > 0
    },
    get hasSubmissions(): boolean {
        return self.submissionIds.length > 0
    },
    get latestSubmissionId(): string {
        return self.submissionIds[self.submissionIds.length - 1]
    }
}))
    .actions((self) => ({
        toggleDone: () => self.done = !self.done,
        save: () => {
            // console.log('saved challenge ', self.id)
        },
        select: () => {
            const parent = getParent<typeof ChallengeList>(self, 2);
            parent.selectChallenge(self as Instance<typeof Challenge>)
        },
        deSelect: () => {
            const parent = getParent<typeof ChallengeList>(self, 2);
            parent.selectChallenge(undefined)
        }
    })).actions(self => ({
        afterCreate() {
            onSnapshot(self, self.save)
        }
    }))


export const Challenge = types.snapshotProcessor(ChallengeTypeWithoutProcessors,
    {
        //Here we transform the challenge from the backend to the model in the way we need it
        preProcessor(snapshot: any): typeof ChallengeTypeWithoutProcessors["CreationType"] {
            if (snapshot) {
                try {
                    if (snapshot?.submissions) {
                        snapshot.submissionIds = snapshot?.submissions?.map((submission: any) => submission.id);
                    }

                    return snapshot;
                } catch (e) {
                    console.error('error while transforming challenge', e);
                    return snapshot;
                }
            }
            return snapshot
        }
    }
)

export default Challenge
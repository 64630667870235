import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import GradientBox, {GradientBoxProps} from "./GradientBox";

type GradientBoxWithBackgroundImage = {
    picture?: string
} & GradientBoxProps


const useStyles = makeStyles((theme) => ({}));

const GradientBoxWithBackgroundImage: React.FC<GradientBoxWithBackgroundImage> = (props) => {

    const {picture, gradientColor, ...rest} = props

    return (
        <Box
            style={{
                background: `linear-gradient(${gradientColor}, ${gradientColor}), url(${picture}) right center / cover  no-repeat`,
                backgroundBlendMode: 'color'
            }}
            {...rest}
        >
            <GradientBox
                height={'100%'}
                width={'100%'}
                gradientColor={gradientColor}
            />
        </Box>);
}

export default observer(GradientBoxWithBackgroundImage)
import React, {useCallback, useState} from 'react';
import {makeStyles, TypographyVariant} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import theme from "../themes/defaultTheme";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {ExpandMore} from "@material-ui/icons";
import {observer} from 'mobx-react';
import AnimateHeight from "react-animate-height";

interface ExpandableTextProps {
    variant?: TypographyVariant,
    text?: string
    initialExpansionstate?: boolean
    lines?: number
}


const useStyles = makeStyles((theme) => ({
    skeleton: {
        backgroundColor: 'rgba(200, 200, 200, 0.11)'
    },
    feedbackText: {
        whiteSpace: 'pre-line',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    fade: {
        position: "absolute",
        bottom: 0,
        left: 0,
        top: 0,
        width: '100%',
        backgroundImage: `linear-gradient(to bottom, transparent, ${theme.palette.primary.main})`
    },
    container: {
        overflow: 'hidden',
    }
}));

/**
 * Will show Skeleton if no text is provided
 * @param variant
 * @param text
 * @param initialExpansionstate
 * @param lines
 * @constructor
 */
const ExpandableText: React.FC<ExpandableTextProps> = ({
                                                           variant = 'body2',
                                                           text,
                                                           initialExpansionstate = false,
                                                           lines = 5
                                                       }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(initialExpansionstate);
    const onRefChange = useCallback(node => {
        if (node && text) {
            try {
                const height = node.offsetHeight
                const lineHeight = parseInt(document?.defaultView?.getComputedStyle(node)['lineHeight'] ?? "0")
                const lines = height / lineHeight;
                if (lines < 4) {
                    setExpanded(true)
                }
            } catch (e) {
                console.error(e)
            }
        }
    }, []);

    return (
        <div>
            <Box position={'relative'}>
                <AnimateHeight
                    className={classes.container}
                    id='text-panel'
                    duration={500}
                    height={!expanded ? lines * ((theme?.typography[variant]?.lineHeight ?? 1) as number) * 10 : 'auto'}
                >
                    <Typography className={classes.feedbackText} variant={variant} ref={onRefChange}>
                        {text ??
                        <React.Fragment>
                            <Skeleton width={150} className={classes.skeleton}/>
                            <Skeleton width={110} className={classes.skeleton}/>
                            <Skeleton width={120} className={classes.skeleton}/>
                            <br/>
                            <Skeleton width={130} className={classes.skeleton}/>
                            <Skeleton width={120} className={classes.skeleton}/>
                        </React.Fragment>
                        }
                    </Typography>
                </AnimateHeight>
                {!expanded && <div className={classes.fade}/>}
            </Box>
            {!expanded && <Button endIcon={<ExpandMore/>} size={'small'} color={"inherit"} variant={'text'}
                                  onClick={() => setExpanded(true)}>Show
                more</Button>}
        </div>
    );
}

export default observer(ExpandableText)
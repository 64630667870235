import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CopyRight from "../../LoginPage/components/CopyRight";
import {observer} from "mobx-react";
import ChallengeModal from "../../../components/ChallengeModal/ChallengeModal";
import ChallengeListProvider from "../../../models/hooks/ChallengeListProvider";
import ChallengeListView from "./components/ChallengeListView";
import IntroductionModal from "../../../components/IntroductionModal/IntroductionModal";
import {useParams} from "react-router-dom";
import BackToDashboardButton from "../../SubmissionPage/components/BackToDashboardButton";


const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        display: 'flex',
        flexGrow: 1,
        overflow: 'auto',
        flexDirection: 'column'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default observer(function StudentChallengePage() {
    const classes = useStyles();


    let {challengeId = 'default'} = useParams<{ challengeId: string }>();


    return (
        <div>
            <ChallengeListProvider challengeListId={challengeId}>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer}/>
                    <ChallengeListView/>
                    <div className={classes.appBarSpacer}/>
                </main>
                <CopyRight/>
                <ChallengeModal/>
                <IntroductionModal/>
            </ChallengeListProvider>
        </div>
    );
})
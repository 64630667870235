import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {observer} from "mobx-react";
import {Dialog, useMediaQuery} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {Skeleton} from "@material-ui/lab";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ChallengeSectionHeader from "./components/ChallengeSectionHeader";
import ChallengeSection from './components/ChallengeSection';
import {useHistory} from "react-router-dom";
import {useChallengeList} from "../../models/hooks/ChallengeListProvider";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";

interface ChallengeModalProps {
}


const useStyles = makeStyles((theme) => ({

    image: {
        minHeight: 300,
        maxHeight: '30%',
        width: '100%',
        position: 'relative'
    },
    imageOverlay: {
        position: 'absolute',
        background: 'linear-gradient(to top, rgba(0,0,0,40), rgba(0,0,0,0))',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(8),
        bottom: 0,
        left: 0,
        right: 0
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5], minHeight: '50%'
    },
    text: {
        color: theme.palette.secondary.contrastText
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

const ChallengeModal: React.FC<ChallengeModalProps> = ({}) => {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const {challengeList} = useChallengeList();
    const challenge = challengeList?.selectedChallenge
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Dialog
            open={!!challenge}
            onClose={() => challengeList?.selectChallenge()}
            aria-labelledby="selected challenge modal"
            aria-describedby="selected-challenge-description"
            fullScreen={fullScreen}
        >
            <CardMedia
                className={classes.image}
                image={challenge?.picture}
                title="Exercises mood image"
            >
                <IconButton aria-label="close" className={classes.closeButton}
                            onClick={() => challengeList?.selectChallenge()}>
                    <Close/>
                </IconButton>
                <Box className={classes.imageOverlay}>
                    <Box className={classes.text}>
                        <Typography variant="subtitle1">
                            {challenge?.title ?? <Skeleton variant="text"/>}
                        </Typography>
                        <Typography variant="caption" color={"secondary"}>
                            {challenge?.category?.toUpperCase() + ' ' ??
                            <Skeleton variant="text" width={'10%'}/>}
                        </Typography>
                        {/*<Typography variant="caption">*/}
                        {/*    | {!!challenge ? "Posted X days ago" : <Skeleton variant="text" width={'30%'}/>}<br/>*/}
                        {/*</Typography>*/}
                        {/*<Typography variant="caption">*/}
                        {/*    {challenge?.numberOfSubmissions ? `Completed by ${challenge.numberOfSubmissions}` : challenge?.numberOfSubmissions === 0 ? 'Not completed yet' :*/}
                        {/*        <Skeleton variant="text" width={'30%'}/>}*/}
                        {/*</Typography>*/}
                    </Box>
                </Box>
            </CardMedia>
            <CardContent>
                <ChallengeSectionHeader text={'Exercise Description'}/>
                <Typography variant="body2" color="textSecondary" component="p">
                    {challenge?.description}
                </Typography>
            </CardContent>
            {challenge?.section_meta.map((section_meta, index) => <ChallengeSection
                section_meta={section_meta}
                key={index}/>)}
            <CardActions>
                <Button size="large" variant={'contained'} fullWidth color="secondary"
                        startIcon={<PlayArrowIcon/>}
                        onClick={() => {
                            if (challenge) {
                                // MainStore.startNewSubmission(challenge.id,challenge)
                                challengeList?.selectChallenge(challenge)
                                history.push(`/student/submission/new/${challenge.id}`)
                            }
                        }}>
                    ENTER EXERCISE
                </Button>
            </CardActions>
        </Dialog>
    );
}

export default observer(ChallengeModal)
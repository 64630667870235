import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import theme from "../../../themes/defaultTheme";
import Typography from "@material-ui/core/Typography";
import ChallengeSection from "../../../components/ChallengeModal/components/ChallengeSection";
import {useSubmission} from "../hooks/SubmissionProvider";
import ChallengeDetails from "./ChallengeDetails";


interface ChallengeDetailSectionProps {
    rightColumnItem?: React.ReactNode,
    full?:boolean
}


const useStyles = makeStyles((theme) => ({
    flex: {
        display: 'flex'
    },
    box: {
        display: 'inline-block',
        width: 20,
        height: 20,
        marginRight: theme.spacing(1),
        alignSelf: 'center'
    },
}));

const ChallengeDetailSection: React.FC<ChallengeDetailSectionProps> = ({rightColumnItem = null,full=false}) => {
    const classes = useStyles();
    const Submission = useSubmission()
    const challenge = Submission?.challenge
    return (
        <Grid container spacing={2}>

            <Grid item xs={12} md={full ? 12 : 6}>
                <ChallengeDetails/>
            </Grid>
            <Grid item xs={12} md={full ? 12 : rightColumnItem ? 3 : 6}>
                {challenge?.section_meta.map((section_meta, index) => <ChallengeSection
                    small
                    section_meta={section_meta} key={index}/>)}
            </Grid>
            {rightColumnItem && <Grid item xs={12} md={full ? 12 : 3}>
                {rightColumnItem}
            </Grid>
            }
        </Grid>

    );
}

export default observer(ChallengeDetailSection)
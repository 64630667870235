import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Skeleton} from "@material-ui/lab";
import {observer} from 'mobx-react'
import {Variant} from "@material-ui/core/styles/createTypography";

interface ChallengeSectionHeaderProps {
    text?: string
    variant?: Variant
}


const useStyles = makeStyles((theme) => ({
    header: {
        // borderBottom: `1px solid ${theme.palette.divider}`,
        color: theme.palette.secondary.main
    }
}));

const ChallengeSectionHeader: React.FC<ChallengeSectionHeaderProps> = ({text, variant = 'h5'}) => {
    const classes = useStyles();
    return (
        <Typography variant={variant} className={classes.header}>
            {text?.toUpperCase() || <Skeleton width={'40%'}/>}
        </Typography>
    );
}

export default observer(ChallengeSectionHeader)
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {Snackbar} from "@material-ui/core";
import {useStores} from "../models/hooks/useStores";
import {Alert} from "@material-ui/lab";
import {useToast} from "../models/hooks/useToast";

interface CustomSnackbarProps {

}


const useStyles = makeStyles((theme) => ({}));

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({}) => {

    const Toast = useToast()

    function handleClose() {
        Toast.hideSnack()
    }

    return (
        <Snackbar
            open={!!Toast?.open}
            autoHideDuration={4000}
            onClose={handleClose}
        >
            <Alert severity={Toast.type ?? 'error'}>
                {Toast.text ?? 'TEST TEST TEST'}
            </Alert>
        </Snackbar>
    );
}

export default observer(CustomSnackbar)
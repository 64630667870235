import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useFeedback, useSubmission, useSubmissionWrapper} from "../../hooks/SubmissionProvider";
import {observer} from 'mobx-react';
import {useToast} from "../../../../models/hooks/useToast";
import {Skeleton} from "@material-ui/lab";

interface ReviewSubmissionSectionProps {
    placeholder?: string,
    isAdmin?: boolean,
    clearInput?: boolean
}


const useStyles = makeStyles((theme) => ({
    reviewTextField: {
        backgroundColor: '#fff'
    }
}));

const ReviewSubmissionSection: React.FC<ReviewSubmissionSectionProps> = ({
                                                                             placeholder = 'Let us know what you thought of the student’s attempt at the challenge….',
                                                                             isAdmin = false,
                                                                             clearInput = false
                                                                         }) => {

    const submission = useSubmission();
    const classes = useStyles();
    const [feedback, setFeedback] = useState('');
    const submittedFeedback = useFeedback()
    const submissionWrapper = useSubmissionWrapper()
    const Toast = useToast();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={'subtitle1'}>
                    PROVIDE STUDENT FEEDBACK FOR: {submission?.creator?.displayName?.toUpperCase() ??  <Skeleton width={100}/>}
                </Typography>
                {!isAdmin && <Typography variant={'subtitle2'}>
                    UpperCampus Prep is designed to help students practice their interview and personal storytelling
                    skills. Candid, real feedback is necessary.
                </Typography>}
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <TextField
                        value={submittedFeedback ? submittedFeedback.summary : feedback}
                        disabled={!!submittedFeedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        InputProps={{
                            className: classes.reviewTextField,
                        }}
                        variant={'outlined'}
                        fullWidth
                        placeholder={placeholder}
                        id="outlined-textarea"
                        multiline
                        rows={10}
                    />
                </Box>
            </Grid>
            {!submittedFeedback ? <Grid container justify="flex-end" xs={12} item>
                <Button
                    disableElevation
                    size={'large'}
                    disabled={!feedback}
                    variant="contained"
                    color={'default'}
                    onClick={() => {
                        if (!feedback || feedback.length < 20) {
                            Toast.showSnack('Please be a bit more specific');
                            return
                        }
                        submissionWrapper?.submitFeedback(feedback)
                        if (clearInput) {
                            setFeedback('');
                        }
                    }}
                >
                    Submit
                </Button>
            </Grid> : null}
        </Grid>
    );
}

export default observer(ReviewSubmissionSection)
import {applySnapshot, cast, detach, flow, getRoot, Instance, types} from "mobx-state-tree";
import Challenge from "./Challenge";
import {getAxiosInstance} from "../../util/AxiosUtil";
import {Toast} from "../hooks/useToast";
import {DateTime} from "./DateTime";
import {FileUploader, FileUploadInfo} from "../hooks/useFileUploader";
import MainStore from "../stores/MainStore";
import {VideoType} from "./VideoType";


export const Creator = types.model('Creator', {
    id: types.identifier,
    name: "Unknown User",
    email: "Unknown Email",
    organizationName: "",
}).views(self => ({
    get displayName() {
        if (self.name !== "Unknown User") {
            return self.name;
        }
        if (self.email !== 'Unknown Email') {
            return self.email
        }
        return self.name

    }
}))

export const Feedback = types.model('Feedback', {
    id: types.identifier,
    summary: "No Content",
    feedback_type: "",
    createdat: DateTime,
    updatedat: DateTime,
    //TODO: remove placeholder
    creator: types.optional(Creator, () => Creator.create({
        id: 'default',
        name: 'Fabian',
        email: 'test@uppercampus.com',
        organizationName: 'UC University'
    })),
    feedback_reviewer_id: types.maybe(types.string),
    submission_id: "",
    title: ""
}).views(self => ({
    get postedRelativlyToNow() {
        return self.createdat.fromNow()
    },
    get isAutomated() {
        return self.feedback_type === 'FACE_DETECTION'
    }
}))

export const Submission = types.model("Submission", {
    id: types.identifier,
    //TODO add flag to see if the video is already fully transcoded
    video_link: types.maybe(types.string),
    videos: types.array(VideoType),
    challenge_id: types.maybe(types.string),
    challenge: types.maybe(Challenge),
    createdat: types.maybe(DateTime),
    new_requested_feedback_emails: types.array(types.string),
    requested_feedback_reviewers: types.array(Creator),
    feedbacks: types.array(Feedback),
    feedback_count: types.maybe(types.number),
    //TODO submissions need to provide the creator - remove these placeholder
    creator: types.maybe(Creator),
    completed: false,
}).views(self => ({
    get firstFeedback(): Instance<typeof Feedback> | undefined {
        return self.feedbacks.length > 0 ? self.feedbacks[0] : undefined
    },
    get hasFeedback(): boolean {
        return self.feedbacks.length > 0
    },
    get feedbackCount() {
        return self.feedbacks.length
    },
    get completedYN() {
        return self.completed ? 'Closed' : 'Open'
    },
    get feedbackSortByDate(): Instance<typeof Feedback>[] {
        return self.feedbacks.slice().sort((firstFeedback, secondFeedback) => secondFeedback.createdat.valueOf() - firstFeedback.createdat.valueOf())
    },
    get requestedReviewers(): Instance<typeof Creator>[] {
        return self.requested_feedback_reviewers
    },
    get hasRequestedReviewers(): boolean {
        return self.requested_feedback_reviewers.length > 0
    },
    get postedRelativlyToNow() {
        return self.createdat?.fromNow() ?? 'Submitted a while ago'
    },
    get creatorName() {
        return self.creator?.name ?? 'Unknown User'
    },
    get finishedTranscoding() {
        return self.videos.length > 0
    },
    get mp4VideoLink() {
        return (self.videos.find(video => video.format === 'mp4_1080p')?.link ?? self.videos.find(video => video.format.includes('mp4'))?.link)
    }
}))
    .actions((self) => ({
        requestFeedback: flow(function* fetchChallenge(email: string) {
            try {
                const response = (yield (yield getAxiosInstance()).post(`/organizations/${MainStore.organizationId}/submissions/${self.id}/feedback/request`, {email})).data
                Toast.showSnack(`Requested feedback from ${email}`, "success")
                self.requested_feedback_reviewers.push({email, id: response.id})
                return response
            } catch (error) {
                Toast.showSnack(`Error requesting feedback from ${email}`)
                console.error(error)
            }
        }),
        fetchChallenge: flow(function* fetchChallenge() {
            try {
                self.challenge = (yield (yield getAxiosInstance()).get(`/organizations/${MainStore.organizationId}/challenges/${self.challenge_id}`)).data
            } catch (error) {
                console.log('failed to fetch challenge')
            }
        }),
        setFeedbackRequestEmails(emails: string[]) {
            self.new_requested_feedback_emails = cast(emails);
        },
        addFeedbackRequestEmail(email: string) {
            if (!self.new_requested_feedback_emails.includes(email)) {
                self.new_requested_feedback_emails.push(email);
            }
        },
        toggleDoneState: flow(function* toggleDoneState() {
            {
                try {
                    yield (yield getAxiosInstance()).post(`/organizations/${MainStore.organizationId}/submissions/${self.id}/done`, {completed: !self.completed})
                    self.completed = !self.completed;
                } catch (error) {
                    console.log('failed to fetch challenge')
                }
            }
        })
    }))
    .actions(self => ({
        requestFeedbackFromAll() {
            self.new_requested_feedback_emails.forEach(email => self.requestFeedback(email))
            applySnapshot(self.new_requested_feedback_emails, [])
        },
        submitFeedback: flow<Instance<typeof Feedback> | undefined, any[]>(function* submitFeedback(feedback: string) {
            if (getRoot<Instance<any>>(self).token) {
                //External reviewer
                const response = (yield (yield getAxiosInstance()).post(`/public/feedback/request/${getRoot<Instance<any>>(self).token}`, {summary: feedback}))
                return Feedback.create(response.data)
            } else {
                const response = (yield (yield getAxiosInstance()).post(`organizations/${MainStore.organizationId}/submissions/${self.id}/feedback`, {summary: feedback}))
                Toast.showSnack('Feedback sent!', 'success')
                self.feedbacks.push(Feedback.create(response.data))
                return undefined
            }
        }),
        afterCreate() {
            if (self.challenge_id && self.id === 'new') {
                self.fetchChallenge()
            }
        }
    }))


export enum NEW_SUBMISSION_STATES {
    PREP = 'PREP',
    RECORDING_TIMER = 'RECORDING_TIMER',
    RECORDING_RECORD = 'RECORDING_RECORD',
    RECORDING_RECORDING_STOPPED = 'RECORDING_RECORDING_STOPPED',
    AFTER_SUBMIT = 'AFTER_SUBMIT'
}

export const ViewSubmissionWrapper = types.model("ViewSubmissionWrapper", {
    id: types.maybe(types.string),
    token: types.maybe(types.string),
    feedback: types.maybe(Feedback),
    submission: types.maybe(Submission)
}).actions((self) => ({
    fetchSubmission: flow(function* fetchSubmission() {
        try {
            self.submission = (yield (yield getAxiosInstance()).get(`/organizations/${MainStore.organizationId}/submissions/${self.id}`)).data
        } catch (error) {
            console.log('failed to fetch submission', error)
        }
    }),
    fetchSubmissionAndFeedbackWithStoredToken: flow(function* fetchSubmissionAndFeedbackWithStoredToken() {
        try {
            const response = (yield (yield getAxiosInstance()).get(`/public/feedback/request/${self.token}`))
            self.submission = response.data.submission
            if (response.data.feedback) {
                self.feedback = Feedback.create(response.data.feedback)
            }
            return true
        } catch (error) {
            console.log('failed to fetch submission/feedback with your token. We assume it is invalid', error)
            return false
        }
    }),
    setFeedback(feedback: Instance<typeof Feedback>) {
        self.feedback = feedback
    }
}))
    .actions(self => ({
        afterCreate() {
            if (self.id) {
                self.fetchSubmission()
            } else if (self.token) {
                self.fetchSubmissionAndFeedbackWithStoredToken()
            }
        },
        submitFeedback(feedback: string) {
            (self.submission?.submitFeedback(feedback))?.then((feedback: Instance<typeof Feedback> | undefined) => {
                if (feedback) {
                    self.setFeedback(feedback);
                }
            })
        },
    }))


const referenceType = types.reference(FileUploadInfo, {
    get(identifier: string) {
        // could be null, but should never be
        // example from here https://mobx-state-tree.js.org/concepts/references doesn't work due to typescript issue
        return FileUploader.files.get(identifier) as Instance<typeof FileUploadInfo>;
    },
    set(value /* User */) {
        return value.uploadId
    }
});
export const NewSubmissionWrapper = types.model("NewSubmissionWrapper", {
    submission: Submission,
    fileUpload: types.maybe(referenceType),
    state: types.optional(types.enumeration<NEW_SUBMISSION_STATES>(Object.values(NEW_SUBMISSION_STATES)), NEW_SUBMISSION_STATES.PREP)
}).actions((self) => ({
    setState(state: NEW_SUBMISSION_STATES) {
        self.state = state
    },
    startUpload: flow(function* submit() {
        try {
            const uploadReference: { bucket: string, key: string, uploadId: string } = (yield(yield getAxiosInstance()).post(`/organizations/${MainStore.organizationId}/challenges/${self.submission.challenge_id}/multipart-uploads`)).data;
            self.fileUpload = FileUploader.addFileUpload(self.submission.challenge?.title || "No Title", uploadReference);
            console.log(`starting upload ${self.fileUpload}`);
        } catch (error) {
            console.log('failed to create new submission', error)
        }
    }),
    createSubmission: flow(function* createSubmission() {
        try {
            console.log(`completing ${JSON.stringify(self.fileUpload)}`)
            const upload_key = self.fileUpload?.complete();
            const submissionUpload = {upload_key};
            const submission = (yield (yield getAxiosInstance()).post(`/organizations/${MainStore.organizationId}/challenges/${self.submission.challenge_id}/submissions`, submissionUpload)).data
            let challenge = undefined;
            if (self.submission.challenge) {
                challenge = detach(self.submission.challenge);
            }
            self.submission = Submission.create({...submission, challenge})
        } catch (error) {
            console.log('failed to create new submission', error)
        }
    }),
}))


export default Submission
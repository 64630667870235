import React, {useEffect} from 'react';
import {Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import StudentChallengePage from "./StudentChallengePage/StudentChallengePage";
import {useStores} from "../../models/hooks/useStores";
import SubmissionPage from "../SubmissionPage/SubmissionPage";
import ChallengeHistoryPage from "./ChallengeHistoryPage/ChallengeHistoryPage";
import NavBar from "../../components/NavBar";
import StudentChallengeListPage from "./StudentChallengeListPage/StudentChallengeListPage";


const StudentPageWrapper: React.FC = ({}) => {
    const {MainStore} = useStores()
    let match = useRouteMatch();
    let location = useLocation();
    useEffect(() => {
        if (!MainStore.currentUser) {
            MainStore.getCurrentUser();
        }
    }, []);
    return (
        <>

            <NavBar
                navigationItems={[
                    {
                        key: "Challenges",
                        link: `${match.path}/challenges`
                    },
                    {
                        key: "My Submissions",
                        link: `${match.path}/history`
                    }
                ]}
                withNavigation={!location.pathname.startsWith(`${match.path}/submission`)}
                elevation={location.pathname.startsWith(`${match.path}/submission`) ? 0 : undefined}/>
            <Switch>
                <Route path={`${match.path}/submission`} component={SubmissionPage}/>
                <Route path={`${match.path}/history`} component={ChallengeHistoryPage}/>
                <Route path={`${match.path}/challenges/:challengeId`} component={StudentChallengePage}/>
                <Route path={`${match.path}/challenges`} component={StudentChallengeListPage}/>
                <Route path={`${match.path}`} component={StudentChallengeListPage}/>
            </Switch>
        </>
    );
}

export default React.memo(StudentPageWrapper, () => true)
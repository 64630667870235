import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Button from "@material-ui/core/Button";
import {Instance} from "mobx-state-tree";
import Challenge from "../../../models/types/Challenge";
import clsx from "clsx";
import {observer} from 'mobx-react';
import {useChallengeList} from "../../../models/hooks/ChallengeListProvider";
import {useHistory} from "react-router-dom";

interface ChallengeCardButtonProps {
    challenge?: Instance<typeof Challenge>
    isHeader?: boolean
}


const useStyles = makeStyles((theme) => ({
    startButton: {
        height: theme.typography.fontSize * 5
    },
    locked: {
        backgroundColor: theme.palette.common.berry
    }
}));


const ChallengeCardButton: React.FC<ChallengeCardButtonProps> = ({challenge, isHeader = false}) => {
    const classes = useStyles();
    const {challengeList} = useChallengeList();
    const history = useHistory()
    let buttonText = 'Start Exercise'
    if (challenge?.isLocked) {
        buttonText = 'Locked'
    } else if (challenge?.done) {
        buttonText = 'Completed'
    } else if (challenge?.hasSubmissions) {
        buttonText = 'View Submission'
    } else if (!challenge && !!challengeList) {
        buttonText = 'All Exercises Completed'
    }
    return (
        <Button
            className={clsx(!isHeader ? classes.startButton : undefined, challenge?.isLocked ? classes.locked : undefined)}
            fullWidth={!isHeader}
            size={'large'}
            variant="contained"
            color={'secondary'}
            startIcon={challenge && !challenge?.done && !challenge?.isLocked && <PlayArrowIcon/>}
            onClick={() => {
                if (challenge?.hasSubmissions) {
                    history.push(`/student/submission/${challenge.latestSubmissionId}`)
                } else {
                    challengeList?.selectChallenge(challenge)
                }
            }}

        >
            {buttonText}
        </Button>
    );
}

export default observer(ChallengeCardButton)
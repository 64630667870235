import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import BackToDashboardButton from "./BackToDashboardButton";
import {useNewSubmission} from "../hooks/SubmissionProvider";
import SubmissionStepper from "../NewSubmissionPage/components/NewSubmissionHeader/components/SubmissionStepper";

interface SubmissionHeaderProps {

}


const useStyles = makeStyles((theme) => ({}));

const SubmissionHeader: React.FC<SubmissionHeaderProps> = ({}) => {

    const NewSubmissionWrapper = useNewSubmission()

    return (
        <Box pb={1}>
            <Grid container alignItems="center">
                <Grid item xs={4}>
                    <BackToDashboardButton/>
                </Grid>
                <Grid item xs={8}>
                    {!!NewSubmissionWrapper && <SubmissionStepper/>}
                </Grid>
            </Grid>
        </Box>
    );
}

export default observer(SubmissionHeader)
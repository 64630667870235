import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, FormControl, FormLabelProps} from "@material-ui/core";
import {ButtonProps} from "@material-ui/core/Button";
import isArray from "lodash/fp/isArray";
import isString from "lodash/fp/isString";

interface FileInputButtonProps {
    accept?: any
    multiple?: boolean,
    id?: string,
    labelProps?: FormLabelProps,
    buttonProps?: ButtonProps,
    onChange?: (file?: File) => void,
    label?: string
}


const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    formControl: {
        margin: theme.spacing(1)
    },
    input: {
        display: "none"
    },
    label: {},
    button: {}
}));

const acceptVariants: any = {
    word:
        ".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    images: "image/*",
    excel:
        ".xlsx,.xls,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
};

const makeAcceptString = (accept: any): string => {
    if (!accept || !accept.length) {
        return "";
    }

    if (isString(accept)) {
        return acceptVariants[accept] ? acceptVariants[accept] : accept;
    }

    if (isArray(accept)) {
        const len = accept.length;
        return accept.reduce((acc, curr, currIndex) => {
            if (acceptVariants[curr]) {
                acc += acceptVariants[curr];
            } else if (curr && curr.length && curr.trim().length) {
                acc += curr.trim();
            }

            if (len > 1 && currIndex < len - 1) {
                acc += ",";
            }

            return acc;
        }, "");
    }

    return "";
};

const FileInputButton: React.FC<FileInputButtonProps> = ({
                                                             accept,
                                                             multiple = false,
                                                             id = "file-input",
                                                             labelProps,
                                                             buttonProps,
                                                             onChange,
                                                             label = "Add File"
                                                         }) => {

    const classes = useStyles();


    const acceptString = useMemo(() => makeAcceptString(accept), [accept]);

    return (
        <div className={classes.container}>
            <FormControl className={classes.formControl}>
                <input
                    value={undefined}
                    id={id}
                    accept={acceptString}
                    className={classes.input}
                    multiple={multiple}
                    type="file"
                    onChange={(event) => {
                        onChange && onChange(event?.target?.files ? event?.target?.files[0] : undefined);
                        if (event?.target ) {
                            const element = event.target as HTMLInputElement
                            element.value = ''
                        }
                    }}
                />
                <label className={classes.label} htmlFor={id} {...labelProps}>
                    <Button
                        variant="contained"
                        color={"secondary"}
                        component="span"
                        className={classes.button}
                        {...buttonProps}
                    >
                        {label}
                    </Button>
                </label>
            </FormControl>
        </div>
    );
}

export default observer(FileInputButton)
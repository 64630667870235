import React from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import {useHistory} from 'react-router-dom';
import {SubmitHandler, useForm} from "react-hook-form";
import {useAuth0} from "@auth0/auth0-react";
import CheckIcon from '@material-ui/icons/Check'
import {Box} from "@material-ui/core";

interface LoginFormProps {

}


const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        height: 150
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface FormProps {
    email: string,
    password: string
}

const LoginForm: React.FC<LoginFormProps> = ({}) => {
    const classes = useStyles();
    const history = useHistory();
    const {register, handleSubmit} = useForm<FormProps>();
    const {loginWithRedirect} = useAuth0();
    const onSubmit: SubmitHandler<FormProps> = (data) => {
        loginWithRedirect()
    }

    return (
        <Container maxWidth={"xs"}>
            <div className={classes.paper}>
                <img className={classes.avatar} src={'/assets/logos/prep_stacked.png'}/>
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={'h5'}>Helping Students Tell Their Story</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <CheckIcon/>
                                <Box ml={2}>
                                    <Typography
                                        variant={'body2'}>Master public speaking and
                                        interview skills from
                                        industry
                                        experts</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <CheckIcon/>
                                <Box ml={2}>
                                    <Typography
                                        variant={'body2'}>Choose from dozens of creative challeneges and real world scenarios</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <CheckIcon/>
                                <Box ml={2}>
                                    <Typography
                                        variant={'body2'}>Receive actionable feedback from schools, friends and professionals</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}

                    >
                        Sign In
                    </Button>

                    <Grid container>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                            <Link onClick={(e: any) => {
                                e.preventDefault()
                                loginWithRedirect({screen_hint: 'signup'})
                            }} href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}
export default LoginForm
import React, {createContext, FC, ReactNode, useContext, useEffect} from 'react';
import ChallengeList, {ChallengeListWrapper} from "../types/ChallengeList";
import {getSnapshot, Instance} from "mobx-state-tree";
import {asReduxStore} from "mst-middlewares";
import Utils from "../../util/Utils";


export type ChallengeListProviderComponent = FC<{
    challengeListId?: string;
    challengeList?: Instance<typeof ChallengeList>;
    children: ReactNode;
}>;

//There always will be a context available. One will be created inside of the render method of the provider.
export const ChallengeListContext = createContext<Instance<typeof ChallengeListWrapper>>(undefined as any);

export const useChallengeList = (): Instance<typeof ChallengeListWrapper> => useContext(ChallengeListContext);


const ChallengeListProvider: ChallengeListProviderComponent = ({
                                                                   children,
                                                                   challengeListId,
                                                                   challengeList
                                                               }): any => {
    let inputChallengeList: undefined | Instance<typeof ChallengeListWrapper> = undefined;
    if (!challengeList && !challengeListId) {
        throw "PROVIDE EITHER challengeList or challengeListId"
    }

    if (challengeList) {
        inputChallengeList = ChallengeListWrapper.create({
            id: challengeList.id,
            challengeList: getSnapshot(challengeList)
        })
    }
    if (!inputChallengeList && challengeListId !== undefined) {
        inputChallengeList = ChallengeListWrapper.create({id: challengeListId})
    }

    useEffect(() => {
        const store = asReduxStore(inputChallengeList as Instance<typeof ChallengeListWrapper>)
        Utils.wrapWithReduxStore(inputChallengeList as Instance<typeof ChallengeListWrapper>)
    }, [])


    return (
        <ChallengeListContext.Provider
            value={inputChallengeList as Instance<typeof ChallengeListWrapper>}>{children}</ChallengeListContext.Provider>
    )
}
export default React.memo(ChallengeListProvider, (prevProps, nextProps) => prevProps.challengeListId === nextProps.challengeListId || prevProps.challengeList === nextProps.challengeList)

import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import TipsEntry from "./TipsEntry";
import Box from "@material-ui/core/Box";
import {useSubmission} from "../../hooks/SubmissionProvider";

interface NewSubmissionTipsSidePanelProps {
    button?: React.ReactNode
}


const useStyles = makeStyles((theme) => ({

    flex: {
        display: 'flex'
    },
}));

const NewSubmissionTipsSidePanel: React.FC<NewSubmissionTipsSidePanelProps> = ({button = null}) => {

    const classes = useStyles();
    const Submission = useSubmission();
    const challenge = Submission?.challenge;
    const tips = challenge?.tips ?? new Array(5).fill(undefined)
    return (
        <Box height={'100%'} className={classes.flex} flexDirection="column"
             justifyContent={'space-between'}>
            {tips.map((tip, index) => <TipsEntry key={index} avatarLetter={(index + 1) + ""}
                                                 text={tip}/>)}
            {button}
        </Box>
    );
}

export default observer(NewSubmissionTipsSidePanel)
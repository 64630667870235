import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import {observer} from 'mobx-react';
import {Instance} from "mobx-state-tree";
import {Skeleton} from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import ChallengeList from "../../models/types/ChallengeList";
import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {PlayArrow} from "@material-ui/icons";
import theme from "../../themes/defaultTheme";
import GradientBoxWithBackgroundImage from "../GradientBoxWithBackgroundImage";
import {Box} from "@material-ui/core";


interface ChallengesListCardProps {
    challengeList?: Instance<typeof ChallengeList>,
    showButton?: boolean
}

const useStyles = makeStyles((theme) => ({

    name: {
        height: theme.typography.fontSize * 3
    },
    cover: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    card: {
        position: 'relative'
    },
    imageBackground: {
        width: '100%',
        backgroundPosition: 'right',
        backgroundSize: 'cover'
    },

    contrastText: {
        color: theme.palette.secondary.contrastText
    },
    descriptionText: {
        whiteSpace: 'pre-line',
    },
    fullSizeButton: {
        height: '100%'
    },
    contentBox: {
        minHeight: 270
    }
}));

const ChallengesListCard: React.FC<ChallengesListCardProps> = ({challengeList, children, showButton = false}) => {

    const classes = useStyles();
    const history = useHistory();
    const color = challengeList?.color ?? theme.palette.grey[300]

    return (
        <Card elevation={0} className={classes.card} style={{
            backgroundColor: color
        }}>
            <Grid container>
                <Grid item container xs={12}>
                    <Grid item xs={12} sm={6}>
                        <Box className={classes.contentBox} p={4} display={'flex'} flexDirection={'column'}>
                            <Box color={theme.palette.getContrastText(color)} display={'flex'} flexDirection={'column'}
                                 flexGrow={1}>
                                <Typography className={classes.descriptionText} variant="body2">
                                    {challengeList?.description ?? <React.Fragment>
                                        <Skeleton width={310}/>
                                        <Skeleton width={210}/>
                                    </React.Fragment>}
                                </Typography>
                                <Typography className={classes.descriptionText} variant="body2">
                                    {challengeList?.goal ? `${'\n'} Goal: ${challengeList?.goal}` : <React.Fragment>
                                        <Skeleton width={310}/>
                                        <Skeleton width={210}/>
                                    </React.Fragment>
                                    }
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                {children}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <GradientBoxWithBackgroundImage
                            picture={challengeList?.image?.scaledUrl}
                            gradientColor={color}
                            height={'100%'}
                        />
                    </Grid>
                </Grid>
                {showButton && <Grid item xs={12}>
                    <Button
                        className={classes.fullSizeButton}
                        size={'large'}
                        variant="contained"
                        fullWidth
                        color={'secondary'}
                        onClick={() => {
                            history.push(`/student/challenges/${challengeList?.id}`)
                        }}
                        endIcon={<PlayArrow/>}
                    >
                        {challengeList?.inProgress ? 'Continue' : challengeList?.isDone ? 'Completed' : 'Start'}
                    </Button>
                </Grid>}
            </Grid>
        </Card>
    );
}


export default observer(ChallengesListCard)
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Instance} from "mobx-state-tree";
import {SectionMeta} from "../../../models/types/Challenge";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ChallengeSectionHeader from "./ChallengeSectionHeader";
import {Icon} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {observer} from 'mobx-react';

interface ChallengeSectionProps {
    section_meta?: Instance<typeof SectionMeta>,
    small?: boolean
}


const useStyles = makeStyles((theme) => ({
    textWithIcon: {
        display: 'flex',
        alignItems: 'center'
    }, icon: {
        padding: theme.spacing(1)
    },
    container: {
        marginBottom: theme.spacing(2)
    }
}));


const ChallengeSection: React.FC<ChallengeSectionProps> = ({section_meta, small = false}) => {
    const classes = useStyles();

    return (
        <CardContent className={classes.container} style={small ? {padding: 0} : undefined}>
            <ChallengeSectionHeader text={section_meta?.title} variant={small ? 'body1' : undefined}/>
            {section_meta?.section_data.map((section_data, index) => (
                    <Typography key={index} variant="body2"
                                color="textSecondary"
                                className={classes.textWithIcon}>
                        <Icon fontSize={small ? 'small' : undefined} className={classes.icon}
                              color={'secondary'}>{section_data.icon}</Icon> {section_data.value ||
                    <Skeleton width={'40%'}/>}
                    </Typography>
                )
            )}

        </CardContent>
    );
}

export default observer(ChallengeSection)
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import theme from "../themes/defaultTheme";
import {Variant} from "@material-ui/core/styles/createTypography";
import {Skeleton} from "@material-ui/lab";

export interface SectionHeaderProps {
    text?: string
    boxColor?: string,
    variant?: Variant | 'inherit';
}


const useStyles = makeStyles((theme) => ({
    box: {
        display: 'inline-block',
        width: theme.typography.h5.fontSize,
        height: theme.typography.h5.fontSize,
        marginRight: theme.spacing(1),
        alignSelf: 'center'
    },
    title: {
        display: 'inline-block',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
}));

const SectionHeader: React.FC<SectionHeaderProps> = ({
                                                                           text,
                                                                           boxColor = theme.palette.common.salmon,
                                                                           variant = 'h5'
                                                                       }) => {

    const classes = useStyles();

    return (
        <div className={classes.titleContainer}>
            <div className={classes.box} style={{backgroundColor: boxColor}}/>
            <Typography className={classes.title} variant={variant}>{!!text ? text :
                <Skeleton variant="text" width={300}/>}</Typography>
        </div>
    );
}

export default observer(SectionHeader)
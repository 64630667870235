import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {BoxProps} from "@material-ui/core/Box/Box";
import theme from "../themes/defaultTheme";
import {Box} from "@material-ui/core";

export type GradientBoxProps = {
    gradientColor: string
} & BoxProps


const useStyles = makeStyles((theme) => ({}));

const GradientBox: React.FC<GradientBoxProps> = (props) => {

    const {gradientColor, color, style = {}, ...rest} = props
    let mixinStyle = {
        ...style,
        background: `linear-gradient(to right, ${gradientColor}, ${gradientColor + '60'}  , ${gradientColor + '40'}`,
        color: color ?? theme.palette.getContrastText(gradientColor)
    }

    return (
        <Box
            style={mixinStyle}
            {...rest}
        />);
}

export default observer(GradientBox)
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {useAuth0} from "@auth0/auth0-react";
import {useHistory, useLocation} from "react-router-dom";
import {Button, CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {useStores} from "../../models/hooks/useStores";
import {accessTokenUtil} from "../../util/AxiosUtil";
import OrganizationCard from "../OrganizationCard";
import {useToast} from "../../models/hooks/useToast";
import ImageSplitContainer from "../ImageSplitContainer";
import useQuery from "../../models/hooks/useQuery";

interface PostLoginProps {

}


const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    grid: {
        justifyContent: 'center',
        display: 'flex',

    }
}));

const PostLogin: React.FC<PostLoginProps> = ({}) => {
    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
    const history = useHistory();
    const location = useLocation<{ returnTo?: string }>()
    const {MainStore} = useStores();
    const Toast = useToast();
    const query = useQuery();

    const [showMultipleOrganizationSelect, setShowMultipleOrganizationSelect] = useState(false);
    const [showInvitation, setShowInvitation] = useState(false);


    accessTokenUtil.get = getAccessTokenSilently;

    const checkUser = async () => {

        await MainStore.getCurrentUser()
        let user = MainStore.currentUser;

        if (user?.hasOpenInvite) {
            setShowInvitation(true)
            return;
        }
        if (MainStore.selectedOrganizationId && !user?.requestedOrganizationContext) {
            //Invalid selectedorganization (user is not allowed to act in context of that organization)
            MainStore.cleanUpStoredPreferences()
            await MainStore.getCurrentUser()
            user = MainStore.currentUser;
        }

        if (user) {
            if (user.hasMultipleOrganizations && !MainStore.selectedOrganizationId) {
                setShowMultipleOrganizationSelect(true)

                return;
            } else {
                if (MainStore.hasPrepFeature) {
                    if (user.requestedOrganizationContext?.roles) {

                        if (MainStore.isAdmin) {
                            history.push(location.state?.returnTo || '/admin/dashboard')
                            return;
                        } else if (MainStore.isStudent) {
                            history.push(location.state?.returnTo || '/student/challenges')
                            return;
                        }
                    }
                }
            }
        }
        history.push('/missingorganization')
    }

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            checkUser()
        }
        if (!isAuthenticated && !isLoading) {
            history.push(`/unauthenticated?error_description=${query.get('error_description')}`)
        }
    }, [isLoading, isAuthenticated]);// eslint-disable-line react-hooks/exhaustive-deps

    const classes = useStyles();

    let contentComponent = (<Grid container>
        <Grid xs={12} className={classes.grid} item>
            <CircularProgress/>
        </Grid>
        <Grid xs={12} className={classes.grid} item>
            <Typography>Logging you in...</Typography>
        </Grid>
    </Grid>);

    if (showInvitation) {

        contentComponent = (<div className={classes.container}>
            <Box>
                <Grid container spacing={4} justify={"center"}>
                    <Grid xs={12} item>
                        <Typography align={'center'} variant={'h6'}>
                            You have been invited by your school to join
                            the {MainStore.currentUser?.firstInvite.name} network on UpperCampus
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container justify={'center'} spacing={1}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color={"default"}
                                onClick={async () => {
                                    await MainStore.currentUser?.declineFirstPreregistration()
                                    Toast.showSnack('Invitation declined')
                                    setShowInvitation(false)
                                    checkUser()
                                }}
                            >
                                Decline
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color={"secondary"}
                                onClick={async () => {
                                    await MainStore.currentUser?.acceptFirstPreregistration()
                                    Toast.showSnack('Invitation accepted', "success")
                                    setShowInvitation(false)
                                    checkUser()
                                }}
                            >
                                Accept
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>)
    }
    if (showMultipleOrganizationSelect) {
        contentComponent = (<Box>
            <Grid container spacing={4} justify={"center"}>
                <Grid xs={12} item>
                    <Typography align={'center'} variant={'h4'}>
                        Select your context:
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <Typography align={'center'} variant={'body2'}>
                        You have multiple organizations. Please select one
                    </Typography>
                </Grid>
                {
                    MainStore.currentUser?.organizations.map(organization => (
                        <React.Fragment key={organization.id}>
                            <Grid xs={12} item>
                                <OrganizationCard onClick={(organization) => {
                                    MainStore.selectOrganization(organization.id);
                                    checkUser()
                                }} organization={organization}/>
                            </Grid>
                        </React.Fragment>
                    ))}
            </Grid>
        </Box>)
    }

    return <ImageSplitContainer>
        <Box display={'flex'} alignSelf={'center'} p={4}>
            {contentComponent}
        </Box>
    </ImageSplitContainer>

}

export default observer(PostLogin)
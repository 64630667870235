import React, {useEffect} from 'react';
import {Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import {useStores} from "../../models/hooks/useStores";
import NavBar from "../../components/NavBar";
import AdminDashboardPage from "./DashboardPage/AdminDashboardPage";
import StudentsManagementPage from "./StudentsManagementPage/StudentsManagementPage";
import ViewSubmissionPage from "../ViewSubmissionPage/ViewSubmissionPage";
import SubmissionsPage from "./SubmissionsPage/SubmissionsPage";


const AdminPageWrapper: React.FC = ({}) => {
    const {MainStore} = useStores()
    let match = useRouteMatch();
    let location = useLocation();
    useEffect(() => {
        if (!MainStore.currentUser) {
            MainStore.getCurrentUser();
        }
    }, []);
    return (
        <>

            <NavBar
                navigationItems={[
                    {
                        key: "DASHBOARD",
                        link: '/admin/dashboard'
                    },
                    {
                        key: "Submissions",
                        link: '/admin/submissions'
                    },
                    {
                        key: "Students",
                        link: '/admin/students'
                    }
                ]}
                withNavigation={!location.pathname.startsWith(`${match.path}/submission/`)}
                elevation={location.pathname.startsWith(`${match.path}/submission`) ? 0 : undefined}
            />
            <Switch>
                <Route path={`${match.path}/submission/:submissionId`} component={ViewSubmissionPage}/>
                <Route path={`${match.path}/submissions`} component={SubmissionsPage}/>
                <Route path={`${match.path}/students`} component={StudentsManagementPage}/>
                <Route path={`${match.path}`} component={AdminDashboardPage}/>
            </Switch>
        </>
    );
}

export default React.memo(AdminPageWrapper, () => true)
import {unstable_createMuiStrictModeTheme as createMuiTheme} from "@material-ui/core";


export const primary = "#072E4E";
export const denim = "#26456d";
export const cobalt = "#1194d1";
export const caribbean = "#6acbbe";
export const coral = "#F05847";
export const rubberDuck = "#f9cd3c";
export const berry = "#8A112C";
export const salmon = "#F79881";


const theme = createMuiTheme({

    palette: {
        primary: {
            main: '#072E4E',
            contrastText: '#ccc',
        },
        secondary: {
            main: cobalt
        },
        common: {
            berry: berry,
            salmon: salmon,
            caribbean: caribbean,
            coral: coral
        },
        error: {
            main: berry,
            light: coral
        }
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0,
            },
        }
    },

});

export default theme;
import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {observer} from "mobx-react";
import LoginPage from "./pages/LoginPage/LoginPage";
import {StoreProvider} from "./models/hooks/context";
import {RootStore} from "./models/stores/RootStore";
import CustomSnackbar from "./components/CustomSnackbar";
import Auth0ProviderWithHistory from "./components/Authentication/Auth0ProviderWithHistory";
import PostLogin from "./components/Authentication/PostLogin";
import ProtectedRoute from "./components/Authentication/ProtectedRoute";
import StudentPageWrapper from "./pages/Student/StudentPageWrapper";
import Utils from "./util/Utils";
import PublicReviewerPage from "./pages/PublicReviewerPage/PublicReviewerPage";
import MissingOrganizationPage from "./pages/MissingOrganizationPage";
import EMailVerificationPage from "./pages/UnauthenticatedPage";
import AdminPageWrapper from "./pages/admin/AdminPageWrapper";
import CustomAlert from "./components/CustomAlert";

const rootStore = new RootStore()
Utils.wrapWithReduxStore(rootStore.MainStore)

const Router = observer(() => {

    return (
        <React.Fragment>
            <BrowserRouter>
                <StoreProvider store={rootStore}>
                    <Auth0ProviderWithHistory>
                        <Switch>
                            <Route exact path={'/'} component={LoginPage}/>
                            <Route exact path={'/signup'} component={LoginPage}/>
                            <Route exact path={'/login'} component={LoginPage}/>
                            <ProtectedRoute path={'/student'} isStudent component={StudentPageWrapper}/>
                            <ProtectedRoute path={'/admin'} isAdmin component={AdminPageWrapper}/>
                            <Route path={'/public/reviewer'} component={PublicReviewerPage}/>
                            <Route exact path={'/postLogin'} component={PostLogin}/>
                            <Route exact path={'/missingorganization'}
                                   component={MissingOrganizationPage}/>
                            <Route exact path={'/unauthenticated'}
                                   component={EMailVerificationPage}/>
                            <Route path={'*'} component={() => <div>Not Found!</div>}/>
                        </Switch>
                    </Auth0ProviderWithHistory>
                </StoreProvider>
            </BrowserRouter>
            <CustomSnackbar/>
            <CustomAlert/>
        </React.Fragment>
    );
})
export default Router;
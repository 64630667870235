import React from 'react';
import {observer} from 'mobx-react';
import {List, ListItem, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ProgressState, useFileUploader} from "../../../models/hooks/useFileUploader";


const useStyles = makeStyles((theme) => ({
    list: {
        width: 350,
        maxWidth: '100%'
    }
}));

const FileUploadList: React.FC = ({}) => {

    const classes = useStyles();
    const FileUploader = useFileUploader();

    const progressInfoLabel = (uploadState: ProgressState, uploadedData: string, totalData: string) => {
        switch (uploadState) {
            case ProgressState.COMPLETED:
                return `Upload of ${totalData} completed.`;
            case ProgressState.GATHERING_DATA:
                return 'Still recording...';
            case ProgressState.WAITING_FOR_UPLOAD_CONFIRMATION:
                return 'Waiting for submission...';
            case ProgressState.UPLOADING:
                return `Uploading: ${uploadedData} of ${totalData}...`
        }
        return '';
    }

    return (
        <List className={classes.list} dense={true}>
            {
                FileUploader.hasUploads ?
                FileUploader.filesArray.filter(x => !x.discarded).map((fileUpload) =>
                <ListItem key={fileUpload.uploadId} selected={fileUpload.completeAfterPartUploadsFinish} divider={true}>
                    <ListItemText
                        primary={fileUpload.displayName}
                        secondary={progressInfoLabel(fileUpload.progressState, fileUpload.uploadedDataSize, fileUpload.totalDataSize)}
                    />
                </ListItem>
                )
                    :
                    <ListItem key='default'>
                        <ListItemText primary={'No current uploads.'} />
                    </ListItem>
            }
        </List>
    );
}

export default observer(FileUploadList)
import {createContext, useContext} from 'react';
import {applySnapshot, types} from "mobx-state-tree";
import {wrapWithReduxStore} from "../../util/Utils";


const MediaDeviceInfo = types.model({
        deviceId: "",
        groupId: "",
        kind: "",
        label: ""
    }
)

export const MediaSettingsType = types.model('MediaSettings', {
    devices: types.array(MediaDeviceInfo),
    cameraId: types.maybe(types.string),
    microphoneId: types.maybe(types.string)
}).views(self => ({
    devicesByType(deviceType: MediaDeviceKind) {
        return self.devices?.filter(device => device.kind === deviceType) ?? []
    }
})).actions(self => ({
    setCameraId(deviceId: string) {
        self.cameraId = deviceId
    },
    setMicrophoneId(deviceId: string) {
        self.microphoneId = deviceId
    },
    getPreloadedDevice(deviceType: MediaDeviceKind, devices?: MediaDeviceInfo[]) {
        const presavedDevice = localStorage.getItem(`uc-practice-${deviceType}`);
        const selectedDeviceId = (presavedDevice && self.devicesByType(deviceType).filter(device => device.deviceId === presavedDevice).length > 0) ? presavedDevice : self.devicesByType(deviceType)[0]?.deviceId;
        if (deviceType === 'videoinput') {
            MediaSettings.setCameraId(selectedDeviceId)
        } else if (deviceType === 'audioinput') {
            MediaSettings.setMicrophoneId(selectedDeviceId)
        }
    },
    setSelectedDevice(deviceId: string, deviceType: MediaDeviceKind) {

        try {
            localStorage.setItem(`uc-practice-${deviceType}`, deviceId);
        } catch (e) {
            console.error('could not set localstorage entry for devicetype, so we will not remember it in the next session :(', e)
        }
        if (deviceType === 'videoinput') {
            MediaSettings.setCameraId(deviceId)
        } else if (deviceType === 'audioinput') {
            MediaSettings.setMicrophoneId(deviceId)
        }
    },

})).actions(self => ({
    fetchDevices(){
        navigator.mediaDevices.enumerateDevices().then(devices => {
            try {
                applySnapshot(self.devices, devices.map(({
                                                             deviceId,
                                                             groupId,
                                                             kind,
                                                             label
                                                         }) => ({
                    deviceId,
                    groupId,
                    kind,
                    label
                })))
                self.getPreloadedDevice('videoinput', devices);
                self.getPreloadedDevice('audioinput', devices);
            } catch (e) {
                console.error(e)
            }
        })
    }
})).actions(self => ({
    afterCreate() {
        self.fetchDevices();
    }
}))

export const MediaSettings = MediaSettingsType.create();

wrapWithReduxStore(MediaSettings)

export const MediaSettingsContext = createContext<typeof MediaSettings>(MediaSettings);


export const useMediaSettings = (): typeof MediaSettings => useContext(MediaSettingsContext);

import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import createListType from "../../../../models/types/listTypes/ListType";
import GridWithListType from "../../../../components/Tables/GridWithListType";
import {Student} from "../../../../models/types/Student";
import Avatar from "@material-ui/core/Avatar";
import MainStore from "../../../../models/stores/MainStore";
import {getAxiosInstance} from "../../../../util/AxiosUtil";
import CsvUploadModal from "../../../../components/CsvUploadModal/CsvUploadModal";
import FileInputButton from "../../../../components/FileInputButton";
import {useToast} from "../../../../models/hooks/useToast";


interface StudentsListManagementViewProps {

}


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    }
}));


const StudentList = createListType(Student, {
    fetchResults: async function (page, pageSize) {
        return (await (await getAxiosInstance()).get<any>(`/organizations/${MainStore.organizationId}/users`, {
            params: {
                page: page,
                limit: pageSize
            }
        })).data
    },
    tableColumns: [
        {field: 'id', headerName: 'ID', hide: true},
        {
            field: 'picture',
            headerName: 'Picture',
            width: 90,
            renderCell: ((params) => {
                return <Avatar src={params.value as string}/>
            }),
            renderHeader: (params) => <React.Fragment/>,
        },
        {field: 'email', headerName: 'Email', flex: 4},
        {field: 'name', headerName: 'Name', flex: 4},
        {field: 'state', headerName: 'State', flex: 4},
        {field: 'roles', headerName: 'Roles', flex: 4}
    ]
}).create()


const StudentsListManagementView: React.FC<StudentsListManagementViewProps> = ({}) => {
    const classes = useStyles();
    const studentList = useRef(StudentList);

    const [file, setFile] = useState<File | undefined>();

    const Toast = useToast()

    useEffect(() => {
        studentList.current.fetch()
    }, []);


    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} container justify={'flex-end'}>
                    <FileInputButton label={"Add Students"} onChange={(file) => {
                        setFile(file)
                    }}/>
                </Grid>
                <Grid item xs={12}>
                    <GridWithListType table listType={studentList.current}/>
                </Grid>
            </Grid>
            <CsvUploadModal initOpened={!!file} csvFile={file}
                            onUpload={(file, mapping) => MainStore.preEnrollStudents(file, mapping)}
                            onClose={() => setFile(undefined)}/>
        </Container>
    );
}

export default observer(StudentsListManagementView);
import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import ReviewSubmissionPage from "../SubmissionPage/ReviewSubmissionPage/ReviewSubmissionPage";


const PublicReviewerPage: React.FC = ({}) => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/:token`} component={ReviewSubmissionPage}/>
        </Switch>
    );
}

export default observer(PublicReviewerPage)
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import useQuery from "../models/hooks/useQuery";
import Link from "@material-ui/core/Link";

interface UnauthenticatedPageProps {

}


const useStyles = makeStyles((theme) => ({}));

/**
 * TODO: Add better page here
 * @constructor
 */
const UnauthenticatedPage: React.FC<UnauthenticatedPageProps> = ({}) => {

    const {loginWithRedirect} = useAuth0();
    const query = useQuery();
    const errorDescription = query.get('error_description')

    let additionalComponent = <Link onClick={(e: any) => {
        e.preventDefault()
        loginWithRedirect()
    }} href="#" variant="body2">
        {"Back to login"}
    </Link>

    if (errorDescription?.includes("verify your email")) {
        additionalComponent = <Button onClick={(e: any) => {
            e.preventDefault()
            loginWithRedirect()
        }}>Resend Verification E-Mail</Button>
    }

    const errorMessage = `Error during authentication: ${errorDescription}`;
    return (
        <div>
            <p>{errorMessage}</p>
            {additionalComponent}
        </div>
    );
}

export default observer(UnauthenticatedPage);

import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {Skeleton} from "@material-ui/lab";

interface TipsEntryProps {
    avatarLetter: string
    text: string
}


const useStyles = makeStyles((theme) => ({
    listicon: {
        backgroundColor: theme.palette.common.caribbean,
        display: 'flex',
        marginRight: theme.spacing(1)
    },
    flex: {
        display: 'flex'
    }
}));

const TipsEntry: React.FC<TipsEntryProps> = ({avatarLetter, text}) => {
    const classes = useStyles();

    return (
        <Box flexDirection="row" className={classes.flex} p={1}
             alignItems={'center'}>
            <Avatar variant={"circle"}
                    className={classes.listicon}>{avatarLetter}</Avatar>
            <Typography className={classes.flex} variant={'caption'}>
                {text || <Skeleton width={200}/>}
            </Typography>
        </Box>
    );
}

export default observer(TipsEntry)
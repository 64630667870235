import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, Menu, MenuItem} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {Skeleton} from "@material-ui/lab";
import {useStores} from "../models/hooks/useStores";
import {useAuth0} from "@auth0/auth0-react";
import PopupStateComp, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {useHistory, useLocation} from "react-router-dom";
import {RouteState} from "./Authentication/ProtectedRoute";
import {PopupState} from "material-ui-popup-state/core";
import {useAlert} from "../models/hooks/useAlert";

interface ProfileButtonProps {

}


const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(1)
    },
    profileButton: {},
    profileButtonText: {
        color: theme.palette.primary.contrastText,
        textTransform: 'none',
        textAlign: 'left',
    },
    namePlaceholder: {
        width: 100,
        backgroundColor: 'rgba(200, 200, 200, 0.11)'
    }
}));

const InnerProfileButton = observer((props: any) => {
    const {MainStore} = useStores()
    const classes = useStyles();
    return <Button className={classes.profileButton} {...bindTrigger(props.popupState)}>
        <Avatar className={classes.avatar} src={MainStore.currentUser?.picture}/>
        <Typography className={classes.profileButtonText}>
            {MainStore.currentUser?.name ??
            <Skeleton variant="text" className={classes.namePlaceholder}/>}
        </Typography>
    </Button>
});

const ProfileButtonMenu = observer(({popupState}: { popupState: PopupState }) => {
    const location = useLocation<RouteState>();
    const {MainStore} = useStores()
    const {logout} = useAuth0();
    const Alert = useAlert();
    const history = useHistory();

    let extraMenuItems: React.ReactNode[] = []
    if (MainStore.isAdmin) {
        if (location.state.isStudent) {
            extraMenuItems.push(<MenuItem key={'view-change'} onClick={() => history.push('/admin/dashboard')}>Admin
                View</MenuItem>)
        } else if (location.state.isAdmin) {
            extraMenuItems.push(
                <MenuItem key={'view-change'} onClick={() => history.push('/student/dashboard')}>Student
                    View</MenuItem>)
        }

    }
    if (MainStore.isUCAdmin) {
        extraMenuItems.push(<MenuItem
            key={'wipe-data'}
            onClick={() => Alert.open('Do you really want to wipe your data?', () => MainStore.wipeData())}>Wipe
            Data</MenuItem>)
    }

    if (MainStore.currentUser?.hasMultipleOrganizations) {
        extraMenuItems.push(<MenuItem
            key={'change-user'}
            onClick={() => {
                MainStore.selectOrganization(undefined)
                history.push('/postLogin')
            }}>Switch Account</MenuItem>
        )

    }
    return (<React.Fragment>
        <InnerProfileButton popupState={popupState}/>
        <Menu
            getContentAnchorEl={null}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            transformOrigin={{vertical: "top", horizontal: "center"}}
            {...bindMenu(popupState)}
        >
            <MenuItem onClick={popupState.close}>Settings</MenuItem>
            {extraMenuItems}
            <MenuItem onClick={() => {
                MainStore.selectOrganization(undefined)
                logout({
                    returnTo: window.location.origin,
                })
            }}>Logout</MenuItem>
        </Menu>
    </React.Fragment>)
})

const ProfileButton: React.FC<ProfileButtonProps> = ({}) => {


    return (
        <PopupStateComp variant="popover" popupId="demo-popup-menu">
            {(popupState) => <ProfileButtonMenu popupState={popupState}/>}
        </PopupStateComp>


    );
}

export default observer(ProfileButton)
import React, {useEffect, useState} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {Tab, Tabs} from "@material-ui/core";
import {observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import ProfileButton from "./ProfileButton";
import clsx from "clsx";
import NotificationButton from "./NotificationButton/NotificationButton";
import FileUploadButton from "./FileUploadButton/FileUploadButton";
import {AppBarProps} from "@material-ui/core/AppBar/AppBar";
import {useProtectedRoute} from "../models/hooks/useProtectedRoute";
import {useHistory, useLocation} from "react-router-dom";
import {FileUploader} from "../models/hooks/useFileUploader";

interface NavBarProps extends AppBarProps {
    withNavigation?: boolean,
    navigationItems?: { key: string, link: string }[]
}


const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    iconButton: {
        marginRight: theme.spacing(1),
    },
    tabs: {
        display: "flex",
        flex: '6 0',
    },
    profile: {
        justifyContent: 'flex-end'
    },
    outerItems: {
        display: "flex",
        flex: '2',
        alignItems: 'center'
    }
}));


const NavBar: React.FC<NavBarProps> = ({withNavigation = false, navigationItems, ...rest}) => {

    const classes = useStyles();
    const isProtectedRoute = useProtectedRoute();
    const history = useHistory();
    const location = useLocation();

    const [activeValue, setActiveValue] = useState(navigationItems?.find(entry => history.location.pathname.startsWith(entry.link))?.link);

    useEffect(() => {
        const newRoute = navigationItems?.find(entry => location.pathname == entry.link)?.link;
        if (newRoute) setActiveValue(newRoute)
    }, [location]);
    return <AppBar position="fixed" {...rest}>
        <Toolbar>
            <div className={classes.outerItems}>
                <img height={50} src={"/assets/logos/prep_horizontal_white_teal.svg"}/>
            </div>
            {withNavigation ?
                <Tabs className={classes.tabs} centered
                      value={activeValue}
                      onChange={(event, value) => {
                          history.push(value)
                      }}
                      aria-label="Main Navigation Tabs">
                    {navigationItems?.map(entry => <Tab key={entry.key} value={entry.link} label={entry.key}/>)}
                </Tabs> : null
            }
            {isProtectedRoute &&
            <div className={clsx(classes.outerItems, classes.profile)}>
                {FileUploader.hasUploads ? <FileUploadButton/> : null}
                <NotificationButton/>
                <ProfileButton/>
            </div>
            }
        </Toolbar>
    </AppBar>;
}
export default observer(NavBar)
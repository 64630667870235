import {createContext, useContext} from 'react';
import {applySnapshot, SnapshotIn, types} from "mobx-state-tree";


export const AlertType = types.model("Alert", {
    text: "",
    show: false,
    submitText: "Yes",
    cancelText: "No",
    title:"Are you sure?"
})
    .volatile(self => ({
        onCancel: undefined as unknown as (() => {}) | undefined,
        onSubmit: undefined as unknown as (() => {}) | undefined
    }))
    .actions(self => ({
        openWithConfig(config: SnapshotIn<typeof self>) {
            applySnapshot(self, {...config, show: true})
        },
        open(text: string, onSubmit?: () => {}, onCancel?: () => {}) {
            self.onCancel = onCancel;
            self.onSubmit = onSubmit;
            self.text = text;
            self.show = true;
        },
        close() {
            self.text = '';
            self.show = false;
        }
    }))
    .actions(self => ({

        submit() {
            if (self.onSubmit) {
                self.onSubmit();
            }
            self.close()
        },
        cancel() {
            if (self.onCancel) {
                self.onCancel()
            }
            self.close()
        }
    }))

export const Alert = AlertType.create();

export const AlertContext = createContext<typeof Alert>(Alert);


export const useAlert = (): typeof Alert => useContext(AlertContext);

import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Skeleton} from "@material-ui/lab";
import theme from "../../../themes/defaultTheme";
import {useSubmission} from "../hooks/SubmissionProvider";

interface ChallengeDetailsProps {

}


const useStyles = makeStyles((theme) => ({}));

const ChallengeDetails: React.FC<ChallengeDetailsProps> = ({}) => {

    const submission = useSubmission();
    const challenge = submission?.challenge

    return (
        <Box>
            <Typography component={'span'} color={'inherit'}>
                <Box mb={1}>
                    <Typography variant="subtitle1">
                        {challenge?.title ?? <Skeleton variant="text"/>}
                    </Typography>
                    <Typography variant="caption" color={"secondary"}>
                        {challenge?.category?.toUpperCase() ??
                        <Skeleton variant="text" width={'30%'}/>}
                        <br/>
                    </Typography>
                    {/*<Typography variant="caption">*/}
                        {/*{!!challenge ? " | Posted X days ago" : <Skeleton variant="text" width={'30%'}/>}*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="caption" gutterBottom>*/}
                    {/*    {challenge?.numberOfSubmissions ? `Completed by ${challenge.numberOfSubmissions}` : challenge?.numberOfSubmissions === 0 ? 'Not completed yet' :*/}
                    {/*        null}*/}
                    {/*</Typography>*/}
                </Box>
                <Typography gutterBottom variant="button"
                            style={{color: theme.palette.common.berry}}>
                    DESCRIPTION
                </Typography>
                <Typography variant="body2">
                    {challenge?.description ? challenge?.description :
                        <Skeleton width={'40%'}/>}
                </Typography>
            </Typography>
        </Box>
    );
}

export default observer(ChallengeDetails)
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {observer} from "mobx-react";
import {useParams} from 'react-router-dom'
import SubmissionProvider from "../hooks/SubmissionProvider";
import NewSubmissionView from "./components/NewSubmissionView";

const useStyles = makeStyles((theme) => ({}));

export default observer(function SubmissionPage() {

    let {challengeId} = useParams<{ challengeId: string }>();

    return (
        <SubmissionProvider challengeId={challengeId}>
            <NewSubmissionView/>
        </SubmissionProvider>
    );
})
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import NavBar from "../../../../components/NavBar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import SubmissionContentPaper from "../../components/SubmissionContentPaper";
import SubmissionRecordingComponent from "./SubmissionRecordingComponent";
import NewSubmissionTipsSidePanel from "./NewSubmissionTipsSidePanel";
import ChallengeDetailSection from "../../components/ChallengeDetailSection";
import VideoOverlay from "./VideoOverlay";
import Button from "@material-ui/core/Button";
import {ArrowRight} from "@material-ui/icons";
import NewSubmissionRecordingSidePanel from "./NewSubmissionRecordingSidePanel";
import {useNewSubmission} from "../../hooks/SubmissionProvider";
import {NEW_SUBMISSION_STATES} from "../../../../models/types/Submission";
import Grid from "@material-ui/core/Grid";
import theme from "../../../../themes/defaultTheme";
import MediaDevicePicker from "../../../../components/MediaDevicePicker";
import SubmissionHeader from "../../components/SubmissionHeader";
import {useHistory} from "react-router-dom";
import ReviewRequester from "../../components/ReviewRequester";


interface NewSubmissionViewProps {

}

const buttonTheme = {
    button: {
        padding: theme.spacing(2)
    },
}

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    blueBG: {
        backgroundColor: theme.palette.primary.main,
        minHeight: '100vh'
    },

    goButton: {
        ...buttonTheme.button,
        backgroundColor: theme.palette.common.caribbean,
    },
    redoButton: {
        ...buttonTheme.button,
        backgroundColor: theme.palette.common.berry,
    },
    endRecordingButton: {
        ...buttonTheme.button,
        backgroundColor: theme.palette.common.coral,
    },
    backToDashboardButton: {
        ...buttonTheme.button,
        backgroundColor: theme.palette.common.salmon,
    },
}));

const NewSubmissionView: React.FC<NewSubmissionViewProps> = ({}) => {
    const classes = useStyles();

    const newSubmission = useNewSubmission();
    const history = useHistory();


    let overlay = null;
    let sidePanel = null;
    let bottomPanel = <ChallengeDetailSection rightColumnItem={
        <>
            <MediaDevicePicker deviceType={'videoinput'}/>
            <MediaDevicePicker deviceType={'audioinput'}/>
        </>
    }/>;
    switch (newSubmission?.state) {
        case NEW_SUBMISSION_STATES.PREP:
            overlay = <VideoOverlay/>
            sidePanel = <NewSubmissionTipsSidePanel button={<Button
                fullWidth
                disableElevation
                className={classes.goButton}
                size={'large'}
                variant="contained"
                color={'secondary'}
                endIcon={<ArrowRight/>}
                onClick={() => {
                    newSubmission?.setState(NEW_SUBMISSION_STATES.RECORDING_TIMER)
                }}
            >
                GO
            </Button>}/>
            break;
        case NEW_SUBMISSION_STATES.RECORDING_TIMER:
            overlay = <VideoOverlay/>
            sidePanel = <NewSubmissionRecordingSidePanel/>
            break;
        case NEW_SUBMISSION_STATES.RECORDING_RECORD:
            overlay = <VideoOverlay/>
            sidePanel = <NewSubmissionRecordingSidePanel button={<Button
                fullWidth
                disableElevation
                className={classes.endRecordingButton}
                size={'large'}
                variant="contained"
                color={'secondary'}
                onClick={() => {
                    newSubmission?.setState(NEW_SUBMISSION_STATES.RECORDING_RECORDING_STOPPED)
                }}
            >
                END RECORDING
            </Button>}/>
            break;
        case NEW_SUBMISSION_STATES.RECORDING_RECORDING_STOPPED:
            sidePanel = <NewSubmissionRecordingSidePanel button={
                <Grid container>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            disableElevation
                            className={classes.redoButton}
                            size={'large'}
                            variant="contained"
                            color={'inherit'}
                            onClick={() => {
                                newSubmission?.setState(NEW_SUBMISSION_STATES.RECORDING_TIMER)
                                newSubmission?.fileUpload?.discard()
                            }}
                        >
                            REDO
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            disableElevation
                            size={'large'}
                            className={classes.goButton}
                            variant="contained"
                            color={'secondary'}
                            onClick={() => {
                                newSubmission?.createSubmission()
                                newSubmission?.setState(NEW_SUBMISSION_STATES.AFTER_SUBMIT)
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            }/>
            break;
        case NEW_SUBMISSION_STATES.AFTER_SUBMIT:
            sidePanel = <NewSubmissionRecordingSidePanel backgroundColor={theme.palette.grey.A700} button={<Button
                fullWidth
                disableElevation
                className={classes.backToDashboardButton}
                size={'large'}
                variant="contained"
                color={'secondary'}
                onClick={() => history.push('/student/challenges')}
            >
                RETURN TO DASHBOARD
            </Button>}/>;
            bottomPanel = <Grid container>
                <Grid item xs={12}>
                    <ReviewRequester/>
                </Grid>
            </Grid>
            overlay = <VideoOverlay/>
            break;
        default:

    }


    return (
        <Box className={classes.blueBG}>
            <div className={classes.appBarSpacer}/>
            <Container maxWidth='lg'>
                <SubmissionHeader/>
                <SubmissionContentPaper
                    VideoComponent={
                        <SubmissionRecordingComponent overlay={overlay}/>
                    }
                    SidePanelComponent={sidePanel}
                    BottomPanelComponent={bottomPanel}
                />

            </Container>
        </Box>
    );
}

export default observer(NewSubmissionView)
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {useHistory} from "react-router-dom";
import Button, {ButtonProps} from "@material-ui/core/Button";
import * as H from "history";

interface ActionButtonWithHistoryProps extends Omit<ButtonProps, 'onClick'> {
    onClick: (history: H.History) => void
}


const useStyles = makeStyles((theme) => ({}));

const ActionButtonWithHistory: React.FC<ActionButtonWithHistoryProps> = ({onClick, children}) => {
    const history = useHistory();
    return (
        <Button variant={'contained'} fullWidth color="secondary"
                endIcon={<PlayArrowIcon/>}
                onClick={() => {
                    if (onClick) onClick(history)
                }}>
            {children}
        </Button>
    );
}

export default observer(ActionButtonWithHistory)
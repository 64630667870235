import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {observer} from "mobx-react";
import {useParams} from 'react-router-dom'
import SubmissionProvider from "../hooks/SubmissionProvider";
import ReviewSubmissionView from "./components/ReviewSubmissionView";

const useStyles = makeStyles((theme) => ({}));

export default observer(function ViewSubmissionPage() {

    let {submissionId, token} = useParams<{ submissionId: string, token: string }>();

    return (
        <SubmissionProvider submissionId={submissionId} token={token}>
            <ReviewSubmissionView/>
        </SubmissionProvider>
    );
})
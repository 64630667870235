import {SnapshotIn, types} from "mobx-state-tree"

export const StudentType = types.model("Student", {
    id: types.identifier,
    email: types.optional(types.string, "no email"),
    firstname: 'Unknown User',
    lastname: "",
    picture: "",
    state: "Unknown",
    roles: types.array(types.string)
})
    .actions(self => ({
        removeFromOrganization(organizationId: string) {
            //TODO Implement removing the user from organization
        }
    }))
    .views(self => ({
        get name() {
            return [self.firstname, self.lastname].join(' ')
        },
    }))


export const Student = types.snapshotProcessor(StudentType,
    {
        //Here we transform the challenge from the backend to the model in the way we need it
        preProcessor(snapshot: SnapshotIn<any>): typeof StudentType["CreationType"] {
            if (snapshot) {
                try {
                    return {
                        ...snapshot,
                        roles: [...snapshot.organizations.map((org: any) => org.organization_user.role), ...snapshot.preregistrations.map((org: any) => org.organization_user.role)]
                    };
                } catch (e) {
                    console.warn('error while transforming OrganisationContextTransformer', e);
                    return snapshot;
                }
            }
            return snapshot
        }
    }
)



import {flow, getEnv, Instance, SnapshotIn, types} from "mobx-state-tree";
import {User} from "../types/User";
import {getAxiosInstance} from "../../util/AxiosUtil";
import {Toast} from "../hooks/useToast";
import * as H from "history";
import * as Sentry from "@sentry/react";

const SELECTED_ORGANIZATION_KEY = 'uc.selected.organization'
export const MainStore = types
    .model("MainStore", {
        currentUser: types.maybe(User),
        selectedOrganizationId: types.maybe(types.string)
    })
    .volatile(self => ({
        history: undefined as unknown as H.History | undefined,
    }))
    .views(self => ({
        get organizationId() {
            return self.selectedOrganizationId ?? self.currentUser?.requestedOrganizationContext?.id ?? 'default'
        },
        get userId() {
            return self.currentUser?.id ?? 'currentuser'
        },
        get isAdmin() {
            return self.currentUser?.requestedOrganizationContext?.roles.includes('Admin')
        },
        hasActiveFeature(featureName: string) {
            return !!self.currentUser?.requestedOrganizationContext?.features.find(feature => feature.feature === featureName)
        },
        get isStudent() {
            return self.currentUser?.requestedOrganizationContext?.roles.includes('Student')
        },
        get isUCAdmin() {
            return self.currentUser?._roles?.includes('UC-Admin')
        },
    }))
    .actions(self => ({
        setHistory(history: H.History) {
            self.history = history;
        },
        onError(message: string, error: Error, showToast: boolean = true) {
            getEnv(self).logger.error(message, error);
            if (showToast) {
                Toast.showSnack(message, 'error',)
            }
        }
    }))
    .actions((self) => ({
        getCurrentUser: flow<Instance<typeof User>, any[]>(function* fetchCurrentUser() {
            const user = (yield (yield getAxiosInstance()).get('users/currentUser', self.selectedOrganizationId ? {headers: {organization_id: self.selectedOrganizationId}} : undefined)).data
            self.currentUser = user
            try {
                Sentry.setUser({email: self.currentUser?.email, username: self.currentUser?.name});
            } catch (e) {
                console.error('Error while setting sentry user', e)
            }
            return user;
        }),
        wipeData: flow(function* wipeData() {
            return (yield (yield getAxiosInstance()).delete(`users/currentuser/wipe`));
        }),
        selectOrganization(organizationId: string | undefined) {
            if (organizationId) {
                localStorage.setItem(SELECTED_ORGANIZATION_KEY, organizationId)
            } else {
                localStorage.removeItem(SELECTED_ORGANIZATION_KEY)
            }
            self.selectedOrganizationId = organizationId
        },
        cleanUpStoredPreferences() {
            localStorage.removeItem(SELECTED_ORGANIZATION_KEY)
            self.selectedOrganizationId = undefined
        },
        preEnrollStudents: flow(function* fetchCurrentUser(csvFile: File, mapping: string[]) {
            const bodyFormData = new FormData();
            bodyFormData.append('csv', csvFile);
            bodyFormData.append('mapping', JSON.stringify(mapping));
            const response = (yield (yield getAxiosInstance()).post(`organizations/${self.organizationId}/users/preregister/csv`, bodyFormData))
            Toast.showSnack(`Invited ${response?.data?.preregisteredStudents?.length} Students ${response?.data?.notPreregisteredStudents?.length > 0 ? `| Failed to invite  ${response?.data?.notPreregisteredStudents?.length} Students` : ''}`, response?.data?.notPreregisteredStudents?.length > 0 ? 'error' : "success")
        })
    })).views(self => ({
        get hasPrepFeature() {
            return self.hasActiveFeature('PREP')
        }
    }))

//Define logger here. We can easily add some other logging methods if needed/wanted
const logger = {
    log(message?: any, ...optionalParams: any[]) {
        console.log(message, ...optionalParams)
    },
    error(message?: any, ...optionalParams: any[]) {
        console.error(message, ...optionalParams)
    },
}
const selectedOrganization = localStorage.getItem(SELECTED_ORGANIZATION_KEY);
let snapshot: SnapshotIn<typeof MainStore> | undefined = undefined;
if (selectedOrganization) {
    snapshot = {selectedOrganizationId: selectedOrganization}
}
export default MainStore.create(snapshot,
    {
        logger: logger // inject logger to the tree
    })
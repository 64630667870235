import {getAxiosInstance} from "../../../util/AxiosUtil";
import MainStore from "../../stores/MainStore";
import React from "react";
import {Instance} from "mobx-state-tree";
import createListType, {ShortListViewType} from "./ListType";
import ChallengeList from "../ChallengeList";

const renderShortView = (challengeList: Instance<typeof ChallengeList>): ShortListViewType => ({
    title: challengeList.title ?? 'Unknown Title',
    id: challengeList.id,
    //TODO: fix challengeList Picture
    picture: undefined,
    subtitle: challengeList.description ?? ''
})


export const ChallengeListListType = createListType(ChallengeList, {
    seeAllClickURL: '/challenges',
    fetchResults: async function (page, pageSize, filter) {
        return (await (await getAxiosInstance()).get<any>(`/organizations/${MainStore.organizationId}/challengelists/sorted`, {
            params: {
                page: page,
                limit: pageSize,
                ...filter
            }
        })).data
    },
    availableFilters: [],
    renderShortView,
})
const Stages = {
    'dev': 'https://practice-api-stg.uppercampus.cloud/',
    'stg': 'https://practice-api-stg.uppercampus.cloud/',
    'prd': 'https://practice-api-prd.uppercampus.cloud/'
}


class StageType {
    public stage: 'dev' | 'stg' | 'prd';

    constructor() {
        this.stage = this.getStage()
    }

    public getStage(): 'dev' | 'stg' | 'prd' {
        if (window.location.origin.includes('localhost')) {
            return 'dev'
        } else if (window.location.origin.includes('stg')) {
            return 'stg'
        }
        return 'prd'
    }

    public get baseUrl(): string {
        return Stages[this.stage]
    }


    public get isDev() {
        return this.stage === 'dev';
    }

    public get isStaging() {
        return this.stage === 'stg';
    }

    public get isPrd() {
        return this.stage === 'prd';
    }
}

export default new StageType();


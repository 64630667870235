import React, {createContext, FC, ReactNode, useContext} from 'react';
import {getSnapshot, Instance} from "mobx-state-tree";
import Submission, {Feedback, NewSubmissionWrapper, ViewSubmissionWrapper} from "../../../models/types/Submission";
import Utils from "../../../util/Utils";


export type SubmissionProviderComponent = FC<{
    challengeId?: string;
    submissionId?: string;
    submission?: Instance<typeof Submission>;
    token?: string;
    children: ReactNode;
}>;


//Context used for new submissions
export const NewSubmissionWrapperContext = createContext<Instance<typeof NewSubmissionWrapper>>(undefined as any);

//There always will be a context available. One will be created inside of the render method of the provider.
export const ViewSubmissionWrapperContext = createContext<Instance<typeof ViewSubmissionWrapper>>(undefined as any);


/**
 * Will always return the closest active submission
 */
export const useSubmission = (): Instance<typeof Submission> | undefined => {
    const newSubmission = useContext(NewSubmissionWrapperContext)
    const wrappedSubmission = useContext(ViewSubmissionWrapperContext);
    if (newSubmission) {
        return newSubmission.submission;
    }
    return wrappedSubmission.submission
};

/**
 * will return a feedback if there is one
 */
export const useFeedback = (): Instance<typeof Feedback> | undefined => {
    const wrappedSubmission = useContext(ViewSubmissionWrapperContext);
    return wrappedSubmission.feedback
};

/**
 * will only be used if the loaded submission is a new submission
 * if you always want to get a submission use SubmissionProvider.useSubmission()
 */
export const useNewSubmission = (): Instance<typeof NewSubmissionWrapper> | undefined => {
    const newSubmission = useContext(NewSubmissionWrapperContext)
    if (newSubmission) {
        return newSubmission;
    }
    return undefined
};


export const useSubmissionWrapper = (): Instance<typeof ViewSubmissionWrapper> | undefined => {
    const submissionWrapper = useContext(ViewSubmissionWrapperContext)
    if (submissionWrapper) {
        return submissionWrapper;
    }
    return undefined
};


const SubmissionProvider: SubmissionProviderComponent = ({
                                                             children,
                                                             challengeId,
                                                             submissionId,
                                                             submission,
                                                             token
                                                         }): any => {
    let inputSubmission: undefined | Instance<typeof ViewSubmissionWrapper> = undefined;
    if (!submission && !submissionId && !challengeId && !token) {
        throw "PROVIDE EITHER submission or submissionId"
    }

    if (submission) {
        inputSubmission = ViewSubmissionWrapper.create({submission: getSnapshot(submission)})
    } else if (submissionId !== undefined) {
        inputSubmission = ViewSubmissionWrapper.create({id: submissionId})
    } else if (token !== undefined) {
        inputSubmission = ViewSubmissionWrapper.create({token})
    } else if (challengeId !== undefined) {
        const newSubmissionWrapper = NewSubmissionWrapper.create({
            submission: {id: 'new', challenge_id: challengeId}
        })

        Utils.wrapWithReduxStore(newSubmissionWrapper)

        return <NewSubmissionWrapperContext.Provider
            value={newSubmissionWrapper as Instance<typeof NewSubmissionWrapper>}>{children}</NewSubmissionWrapperContext.Provider>
    }


    Utils.wrapWithReduxStore(inputSubmission)

    return (
        <ViewSubmissionWrapperContext.Provider
            value={inputSubmission as Instance<typeof ViewSubmissionWrapper>}>{children}</ViewSubmissionWrapperContext.Provider>
    )
}

export default React.memo(SubmissionProvider, (prevProps, nextProps) => {
    return prevProps.challengeId === nextProps.challengeId ||
        prevProps.submissionId === nextProps.submissionId ||
        prevProps.submission === nextProps.submission ||
        prevProps.token === nextProps.token
})
import React, {useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SectionHeader from "../../../../components/SectionHeader";
import Container from "@material-ui/core/Container";
import GridWithListType from "../../../../components/Tables/GridWithListType";
import {StudentSubmissionListType} from "../../../../models/types/listTypes/SubmissionListType";


interface ChallengeHistoryViewProps {

}


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    }
}));


const SubmissionListType = StudentSubmissionListType.create()


const ChallengeHistoryView: React.FC<ChallengeHistoryViewProps> = ({}) => {
    const classes = useStyles();
    const submissionList = useRef(SubmissionListType);
    useEffect(() => {
        submissionList.current.fetch()
    }, []);


    return (
        <Container className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <SectionHeader text={"My Submissions"}/>
                </Grid>
                <Grid item xs={12}>
                    <GridWithListType listType={submissionList.current}
                                      onCellClick={(a, b) => b.preventDefault()}
                                      onRowClick={(submission, e) => {
                                          e.preventDefault()

                                      }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default observer(ChallengeHistoryView)
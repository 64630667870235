import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {Popover} from "@material-ui/core";
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state';
import {useFileUploader} from "../../models/hooks/useFileUploader";
import FileUploadList from "./components/FileUploadList";

interface FileUploadButtonProps {

}


const useStyles = makeStyles((theme) => ({}));

const InnerFileUploadButton = observer((props: any) => {
    const FileUploader = useFileUploader()
    return <IconButton color="inherit" {...bindTrigger(props.popupState)}>
        <Badge badgeContent={FileUploader.pendingUploadsCount} color="secondary">
            <CloudUploadIcon/>
        </Badge>
    </IconButton>
});


const FileUploadButton: React.FC<FileUploadButtonProps> = ({}) => {

    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState: any) => (
                <div>
                    <InnerFileUploadButton popupState={popupState}/>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <FileUploadList/>
                    </Popover>
                </div>
            )}
        </PopupState>
    );
}

export default observer(FileUploadButton)
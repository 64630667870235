import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Challenge from '../../models/types/Challenge';
import {Instance} from "mobx-state-tree";
import {Skeleton} from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {CheckCircle, LockRounded} from "@material-ui/icons";
import theme from "../../themes/defaultTheme";
import {observer} from "mobx-react";
import ChallengeCardButton from './components/ChallengeCardButton'
import {BackgroundImg} from 'react-cloudimage-responsive';


interface ChallengeCardProps {
    challenge?: Instance<typeof Challenge>
}

const useStyles = makeStyles((theme) => ({

    name: {
        height: theme.typography.fontSize * 4
    },
    cover: {
        width: '100%',
        height: '100%',
        backgroundSize: "cover",
        backgroundPosition: "right"
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndex.mobileStepper,
        backgroundColor: theme.palette.secondary.main + 'CC',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.secondary.contrastText
    },
    card: {
        position: 'relative'
    },
}));

const ChallengeCard: React.FC<ChallengeCardProps> = ({
                                                         challenge,
                                                     }) => {

    const classes = useStyles();

    return (
        <Card className={classes.card}>
            {challenge?.isLocked ? <div className={classes.overlay} style={{
                backgroundColor: theme.palette.common.berry + 'CC'
            }}>
                <LockRounded fontSize={"large"}/>
            </div> : null
            }
            {challenge?.done ? <div className={classes.overlay}>
                <CheckCircle fontSize={"large"}/>
            </div> : null
            }
            <Grid container>
                <Grid item xs={6}>
                    <CardContent>
                        <Box mb={1}>
                            <Typography variant="subtitle2" color={'secondary'} gutterBottom>
                                {challenge?.category?.toUpperCase() ?? <Skeleton variant="text" width={'10%'}/>}
                            </Typography>
                        </Box>
                        <Box className={classes.name} mb={1}>
                            <Typography variant="subtitle2">
                                {challenge?.title ?? <Skeleton variant="text"/>}
                            </Typography>
                        </Box>
                        {/*<Typography variant="caption" style={{color: theme.palette.common.salmon}}>*/}
                        {/*    {challenge?.numberOfSubmissions ? `Completed by ${challenge.numberOfSubmissions}` : challenge?.numberOfSubmissions === 0 ? 'Not completed yet' :*/}
                        {/*        <Skeleton variant="text" width={'30%'}/>}*/}
                        {/*</Typography>*/}
                    </CardContent>
                    <ChallengeCardButton challenge={challenge}/>
                </Grid>
                <Grid item xs={6}>

                    {challenge?.picture ? <BackgroundImg src={challenge?.picture}
                                                         className={classes.cover}/> :
                        <Skeleton variant="rect" width="100%" height="100%"/>}
                </Grid>
            </Grid>
        </Card>
    );
}


export default observer(ChallengeCard)
import {
    _NotCustomized,
    applySnapshot,
    cast,
    flow,
    IAnyType,
    IArrayType,
    IModelType,
    Instance,
    IReferenceType,
    IType,
    SnapshotIn,
    types
} from "mobx-state-tree";
import {GridColDef} from '@material-ui/data-grid';
import get from 'lodash.get';

interface PaginatedResponse<T> {
    count: number,
    previous?: string,
    next?: string,
    results: T[]
}


export interface ShortListViewType {
    id: string,
    title: string,
    subtitle: string,
    picture?: string
}

export interface ListTypeFilterType {
    [key: string]: string | number | boolean
}

interface ListTypeConfig<T extends IAnyType> {
    fetchResults: (page: number, pageSize: number, filter: ListTypeFilterType) => Promise<PaginatedResponse<T["CreationType"]>>
    infiniteScrolling?: boolean,
    tableColumns?: GridColDef[],
    renderShortView?: (entity: Instance<T>) => ShortListViewType,
    seeAllClickURL?: string,
    availableFilters?: SnapshotIn<(typeof Filter)>[],
    skipAddingOfDefaultFilter?: boolean
}

export interface ListType<T extends IAnyType> extends IModelType<{
    count: IType<number | undefined, number, number>,
    page: IType<number | undefined, number, number>,
    pageSize: IType<number | undefined, number, number>,
    seeAllClickURL: IType<string | undefined, string, string>,
    next: IType<boolean | undefined, boolean, boolean>,
    loading: IType<boolean | undefined, boolean, boolean>,
    filter: IArrayType<IReferenceType<typeof Filter>>
    results: IArrayType<T>
},
    { readonly pages: number } &
    {
        fetchPage: (page: number, pageSize?: (number | undefined)) => Promise<void>,
        setFilterByKey: (filter?: string) => void
    } & { fetch(): void } & {
    readonly availableFilters: Instance<typeof Filter>[],
    readonly columns: GridColDef[], readonly rows: { [p: string]: any }[], readonly shortListView: ShortListViewType[], readonly filtersAsURLParamString: string, readonly filtersAsURLParamStringWithFilterKey: string, filterByKey: (id: string) => Instance<typeof Filter> | undefined, readonly activeFirstFilter: Instance<typeof Filter> | undefined
}, _NotCustomized, _NotCustomized> {
}


export const Filter = types.model('FilterType', {
    key: types.identifier,
    value: types.union(types.string, types.number, types.boolean),
    label: types.string,
    url_param: types.maybe(types.string)
}).views((self) => ({
    get filter_key() {
        return self.url_param ?? self.key
    }
}))

function createListType<T extends IAnyType>(type: T, config: ListTypeConfig<T>): ListType<T> {
    const {
        fetchResults,
        infiniteScrolling = false,
        tableColumns,
        renderShortView,
        seeAllClickURL = '',
        availableFilters = [],
        skipAddingOfDefaultFilter = false
    } = config;
    if (!skipAddingOfDefaultFilter) {
        availableFilters.unshift({
            key: 'no_filter', value: true, label: 'Show All'
        })
    }
    const availableFiltersAsInstance = availableFilters.map(filter => Filter.create(filter))

    const filterReference = types.reference(Filter, {
        get(identifier, parent: any /*Store*/) {
            return availableFiltersAsInstance.find((filter: Instance<typeof Filter>) => filter.key === identifier) as any || null
        },
        // given a user, produce the identifier that should be stored
        set(value /* FIlter */) {
            return value.key
        }
    })

    return types.model(`ListType-${type.name}`, {
        count: 0,
        page: 1,
        pageSize: 10,
        next: true,
        loading: false,
        results: types.array(type),
        filter: types.array(filterReference),
        seeAllClickURL: seeAllClickURL
    })
        .views(self => ({
            get pages() {
                return self.count / self.pageSize
            },
            get availableFilters() {
                return availableFiltersAsInstance
            },
            get filtersAsObjectWithFilterKey() {
                return Array.from(self.filter.values()).reduce((a, b) => ({[b.filter_key]: b.value, ...a}), {})
            },
            get filtersAsObject() {
                return Array.from(self.filter.values()).reduce((a, b) => ({[b.key]: b.value, ...a}), {})
            },
            filterByKey(key: string) {
                return availableFiltersAsInstance.find(filter => filter.key === key);
            }
        }))
        .actions(self => ({
            fetchPage: flow(function* fetchMore(page: number, pageSize: number = self.pageSize) {
                    self.loading = true;
                    try {
                        self.page = page;
                        const response = yield fetchResults(page, pageSize, self.filtersAsObjectWithFilterKey)
                        self.count = response.count
                        if (infiniteScrolling) {
                            self.results.push(...response.results)
                        } else {
                            self.results = cast(response.results)
                        }
                    } catch (e) {
                        console.error(e)
                    }
                    self.loading = false;
                }
            ),
            setFilterByKey(filterKey?: string) {
                if (filterKey) {
                    applySnapshot(self.filter, [filterKey])
                } else {
                    applySnapshot(self.filter, [])

                }
            }
        })).actions(self => ({
            fetch() {
                return self.fetchPage(self.page, self.pageSize);
            }
        })).views(self => ({
            get columns() {
                if (!tableColumns) {
                    throw "No columns provided to ListStore";
                }
                // tableColumns.forEach(column => {
                //     if(column.renderCell){
                //         column.renderCell= (params: GridCellParams) => {
                //
                //             return column.renderCell(params,)
                //         };
                //     }
                // })
                tableColumns.forEach((column) => {
                    column.disableColumnMenu = true
                    column.sortable = false
                })
                return tableColumns
            },
            get rows() {
                if (!tableColumns) {
                    throw "No columns provided to ListStore";
                }
                return self.results.map(result => tableColumns?.reduce((object, column) => ({
                    ...object,
                    [column.field]: get(result, column.field)
                }), {main: result} as { [key: string]: any }))
            },
            get shortListView(): ShortListViewType[] {
                if (!renderShortView) {
                    throw "No renderShortView provided to listtype";
                }
                if (self.count === 0 && self.loading) {
                    return new Array(2).fill(undefined)
                } else if (self.count === 0) {
                    return []
                } else {
                    return self.results.map(renderShortView)
                }
            },
            get filtersAsURLParamString() {
                return '?' + new URLSearchParams(self.filtersAsObject).toString();
            },
            get filtersAsURLParamStringWithFilterKey() {
                return '?' + new URLSearchParams(self.filtersAsObjectWithFilterKey).toString();
            },
            get activeFirstFilter() {
                return self.filter.length > 0 ? self.filter[0] : self.availableFilters[0]
            }
        }))
}

export default createListType
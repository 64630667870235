import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import CopyRight from "../pages/LoginPage/components/CopyRight";

interface ImageSplitContainerProps {

}


const useStyles = makeStyles((theme) => ({
    image: {
        backgroundColor: theme.palette.primary.main,
        zIndex: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {height: '100vh'},
    grid: {height: '100%'},
    backgroundU: {
        backgroundImage: "url('/assets/backgrounds/COBALT.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 500,
        height: '50%',
        width: '50%'
    },
    loginPanel: {
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const ImageSplitContainer: React.FC<ImageSplitContainerProps> = ({children}) => {
    const classes = useStyles();
    return (
        <Container className={classes.container} component="main" disableGutters maxWidth={'xl'}>
            <Grid className={classes.grid} container>
                <Grid item xs={true} sm={4} md={7} className={classes.image}>
                    <div className={classes.backgroundU}>
                        {/*<img height={50} src={"/assets/logos/prep_horizontal_white_teal.svg"}/>*/}
                    </div>
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} className={classes.loginPanel}>
                    {children}
                </Grid>
            </Grid>
            <CopyRight name={'UpperCampus'}/>
        </Container>
    );
}

export default observer(ImageSplitContainer)
import React from 'react';
import {observer} from 'mobx-react';
import {makeStyles} from "@material-ui/core/styles";
import SectionHeader from "../../../../components/SectionHeader";
import {Box} from "@material-ui/core";
import ChallengeList from "../../../../models/types/ChallengeList";
import {Instance} from 'mobx-state-tree';
import ChallengesListCard from "../../../../components/ChallengeListCard/ChallengesListCard";
import ChallengeListProgress from "../../../../components/ChallengeListProgress";

interface ChallengeListCardProps {
    challengeList?: Instance<typeof ChallengeList>
}


const useStyles = makeStyles((theme) => ({}));

const ChallengesListEntry: React.FC<ChallengeListCardProps> = ({challengeList}) => {

    return (
        <Box>
            <Box mb={3}>
                <SectionHeader boxColor={challengeList?.color} text={challengeList?.title}/>
            </Box>
            <Box>
                <ChallengesListCard challengeList={challengeList} showButton>
                    <ChallengeListProgress challengeList={challengeList} contrastText/>
                </ChallengesListCard>
            </Box>
        </Box>
    );
}

export default observer(ChallengesListEntry);